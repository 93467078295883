<div class="auth-wrapper d-flex flex-column flex-lg-row overflow-x-hidden bg-page w-100 h-100 {{ pageType.toLowerCase() }}">
  <div class="auth-content d-none d-lg-flex flex-column overflow-hidden">
    <div class="content w-100 position-relative">
      <img class="img-fluid image w-100 h-100" src="assets/images/landing/auth.png" alt="Login Background" />
    </div>
  </div>

  <div class="forms-wrapper page-wrapper d-flex flex-column bg-primary">
    <div class="d-flex flex-column flex-sm-row justify-content-center align-items-center justify-content-sm-between">
      <app-button
        btnLabel="app_language"
        btnClass="without-shadow font-toggle text-secondary"
        iconPosition="left"
        color="primary"
        (onClick)="changeLanguage()"
      >
        <em class="db-icon-world font-20"></em>
      </app-button>

      <p class="text-secondary align-self-sm-end mt-3 font-10 d-flex align-items-center gap-1">
        {{ "AUTH." + pageType + "_HINT" | translate }}

        <a [routerLink]="isSignIn ? ['/auth/register'] : ['/auth/login']">
          <app-button
            color="primary"
            btnClass="without-shadow text-white fw-bold font-16 px-2"
            [btnLabel]="isSignIn ? 'AUTH.CREATE_ACCOUNT' : 'AUTH.LOGIN'"
          />
        </a>
      </p>
    </div>

    <div class="forms-content d-flex flex-column justify-content-center h-100">
      <div class="d-flex flex-column align-items-center justify-content-center animate gap-3 mb-2">
        <a routerLink="/">
          <app-button btnClass="without-shadow text-white bg-transparent font-20 h-40 px-2" [btnLabel]="'HOME'" />
        </a>

        <h6 class="m-0 font-24 text-secondary">{{ "AUTH." + pageType | translate }}</h6>
      </div>

      <router-outlet></router-outlet>
    </div>
  </div>
</div>
