import { Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject } from "rxjs";
import { environment } from "../../../../environments/environment";
import { Languages } from "../../enums";

@Injectable({
  providedIn: "root",
})
export class TranslationService {
  private defaultLanguage: string = localStorage.getItem("currentLang") || environment.defaultLanguage;
  private language = "";

  private renderer: Renderer2;
  private currentLanguage = new BehaviorSubject<string>(this.defaultLanguage);

  currentLanguage$ = this.currentLanguage.asObservable();

  constructor(
    private translate: TranslateService,
    private rendererFactory: RendererFactory2,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.initLanguage();
  }

  get isEnglish() {
    return this.translate.currentLang === Languages.EN;
  }

  changeLanguage() {
    this.language = this.language === Languages.EN ? Languages.AR : Languages.EN;
    localStorage.setItem("currentLang", this.language);

    this.translate.use(this.language);
    this.handleBasicLogic();
  }

  private initLanguage() {
    const lang = localStorage.getItem("currentLang");

    if (lang) {
      this.language = lang;
    } else {
      this.language = this.defaultLanguage;
      localStorage.setItem("currentLang", this.language);
    }

    this.translate.use(this.language);
    this.handleBasicLogic();
  }

  private handleBasicLogic() {
    if (this.language === "ar") {
      this.renderer.addClass(document.body, "rtl");
      this.renderer.setAttribute(document.body, "dir", "rtl");
      this.renderer.setAttribute(document.querySelector("html"), "lang", "ar");
      this.currentLanguage.next(this.language);
    } else {
      this.renderer.removeClass(document.body, "rtl");
      this.renderer.setAttribute(document.body, "dir", "ltr");
      this.renderer.setAttribute(document.querySelector("html"), "lang", "en");
      this.currentLanguage.next(this.language);
    }
  }
}
