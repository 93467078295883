import { Routes } from "@angular/router";

export const authRoutes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  {
    path: "",
    loadComponent: () => import("./pages/auth/auth.component").then((c) => c.AuthComponent),
    children: [
      {
        path: "login",
        data: { type: "SIGNIN" },
        loadComponent: () => import("./components/login/login.component").then((c) => c.LoginComponent),
      },
      {
        path: "register",
        data: { type: "REGISTER" },
        loadComponent: () => import("./components/register/register.component").then((c) => c.RegisterComponent),
      },
      {
        path: "forget-password",
        children: [
          {
            path: "",
            data: { type: "FORGET_PASSWORD" },
            loadComponent: () => import("./components/forget-password/forget-password.component").then((c) => c.ForgetPasswordComponent),
          },
          {
            path: "otp",
            data: { type: "FORGET_PASSWORD_OTP" },
            loadComponent: () =>
              import("./components/forget-password-otp/forget-password-otp.component").then((c) => c.ForgetPasswordOtpComponent),
          },
          {
            path: "email",
            data: { type: "FORGET_PASSWORD_EMAIL" },
            loadComponent: () =>
              import("./components/forget-password-email/forget-password-email.component").then((c) => c.ForgetPasswordEmailComponent),
          },
        ],
      },
      {
        path: "verify",
        data: { type: "VERIFY_ACCOUNT" },
        loadComponent: () => import("./components/verify/verify.component").then((c) => c.VerifyComponent),
      },
    ],
  },
];
