import { finalize, map } from "rxjs";
import { inject, Injectable } from "@angular/core";

import { HttpService } from "../http/http.service";
import { AuthService } from "../auth/auth.service";
import { ParentServiceCode, ServiceCodes, TourServicesCodes, UserRole } from "../../enums";
import { Dropdown, GetPagedBody, Lookup, ReservationVisitorCategoriesPrice, Role, Service } from "../../interfaces";

import { MuseumBuildingService } from "src/app/features";

@Injectable({
  providedIn: "root",
})
export class LookupsService extends HttpService {
  private _baseUrl = "lookups/";
  private museumBuildingService = inject(MuseumBuildingService);
  private authService = inject(AuthService);

  private set baseUrl(_baseUrl: string) {
    this._baseUrl = _baseUrl;
  }

  protected override get baseUrl(): string {
    return this._baseUrl;
  }

  get isAdminRoute() {
    const userToken = this.convertTokenJWT();
    if (!userToken) return false;

    return [UserRole.ADMIN, UserRole.COUNTER].includes(userToken.RoleCode);
  }

  getReservationTypes() {
    return this.get<Lookup[]>({ APIName: "reservation-types" });
  }

  getReservationServices() {
    return this.get<Service[]>({ APIName: "get-reservation-services" }).pipe(
      map((services) => {
        services = this.setServiceRoute(services);

        return services.filter((service) => {
          if (service.code === TourServicesCodes.TourismCompanies && this.authService.isCompanyUser) return false;

          return true;
        });
      }),
    );
  }

  getReservationVisitorCategoriesPrice(
    reservationTypeCode: ParentServiceCode,
    reservationTicketTypeCode: ServiceCodes,
    museumBuildingId: string | null = null,
  ) {
    this.baseUrl = "reservationprice/";

    const body = {
      reservationTypeCode,
      reservationTicketTypeCode,
      museumBuildingId,
    };

    return this.post<ReservationVisitorCategoriesPrice[]>({
      APIName: `get`,
      body,
    }).pipe(finalize(() => (this.baseUrl = "lookups/")));
  }

  getMuseumBuildingsFreeEntryTicket() {
    const body: GetPagedBody<Record<string, string | number | boolean>> = {
      pageSize: 50,
      pageNumber: 1,
      orderByValue: [
        {
          colId: "id",
          sort: "asc",
        },
      ],
      filter: {
        allowFreeTicket: true,
      },
    };

    return this.museumBuildingService.getPaged(body).pipe(map((response) => response.data));
  }

  getMuseumBuildingsEntranceTicket() {
    const body: GetPagedBody<Record<string, string | number | boolean>> = {
      pageSize: 50,
      pageNumber: 1,
      orderByValue: [
        {
          colId: "id",
          sort: "asc",
        },
      ],
      filter: {
        allowPaidTicket: true,
      },
    };

    return this.museumBuildingService.getPaged(body).pipe(map((response) => response.data));
  }

  getDeviceTypes(reservationTypeCode: ServiceCodes) {
    this.baseUrl = "lookups/";

    return this.get<(Lookup & { price: number })[]>({ APIName: `get-service-types/${reservationTypeCode}` });
  }

  getEventsTypes() {
    return this.get<Service[]>({ APIName: "get-event-types" });
  }

  getRoles() {
    return this.get<Role>({ APIName: "get-roles" });
  }

  getTicketTypes() {
    return this.get<Lookup[]>({ APIName: "get-ticket-types" });
  }

  getProductTypes() {
    return this.get<Lookup[]>({ APIName: "get-product-types" });
  }

  getOrganizationTypes(parentCode: keyof typeof ParentServiceCode) {
    this.baseUrl = "lookups/";
    return this.get<Lookup[]>({ APIName: `get-organization-types/${parentCode}` });
  }

  getOrganizationLocations() {
    return this.get<Lookup[]>({ APIName: "get-organization-location" });
  }

  getServiceTypes() {
    return this.get<Lookup[]>({ APIName: "get-service-types" });
  }

  private setServiceRoute(services: Service[]) {
    return services.map((service) => {
      let serviceRoute = "";

      serviceRoute = this.isAdminRoute
        ? `/admin/booking-services/book/${service.parentCode}/${service.code}`
        : `/services/book/${service.parentCode}/${service.code}`;

      return {
        ...service,
        serviceRoute,
      };
    });
  }

  getLanguages() {
    return this.get<Lookup[]>({ APIName: "get-languages" });
  }

  getEducationLevels() {
    return this.get<Lookup[]>({ APIName: "get-educational-levels" });
  }

  getCafeItems() {
    return this.get<Lookup[]>({ APIName: "get-cafe-items" });
  }

  getUserCategories() {
    return this.get<Lookup[]>({ APIName: "get-user-categories" });
  }

  getTargetedCategories() {
    return this.get<Lookup[]>({ APIName: "get-targeted-categories" });
  }

  getTourTypes() {
    return this.get<Lookup[]>({ APIName: "get-tour-types" });
  }

  getCountries(params: { code?: string } = {}) {
    return this.get<Dropdown[]>({ APIName: "get-countries", params });
  }

  getGovernorates(params: { code?: string; id?: number } = {}) {
    return this.get<Dropdown[]>({ APIName: `get-governorates`, params });
  }

  getStates(params: { code?: string; id?: number } = {}) {
    return this.get<Dropdown[]>({ APIName: `get-states`, params });
  }
}
