import { ThemePalette } from "@angular/material/core";
import { MatFormFieldAppearance } from "@angular/material/form-field";
import { Component, Input, inject } from "@angular/core";
import { ControlContainer, ControlValueAccessor, FormControl, FormGroup } from "@angular/forms";

@Component({
  template: ``,
})
export abstract class ReactiveFormsBaseComponent implements ControlValueAccessor {
  private controlContainer = inject(ControlContainer);

  @Input({ required: true }) formControlName!: string;
  @Input() appearance: MatFormFieldAppearance = "outline";
  @Input() color: ThemePalette = "primary";

  get form() {
    return this.controlContainer.control as FormGroup;
  }

  get control() {
    return this.form.get(this.formControlName) as FormControl;
  }

  get value() {
    if (!this.control) return null;

    return this.control.value;
  }

  onChange: (_: unknown) => void = () => {};
  onTouched: (_: unknown) => void = () => {};

  writeValue(_: unknown): void {}

  registerOnChange(fn: (_: unknown) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: (_: unknown) => void): void {
    this.onTouched = fn;
  }
}
