<div class="content-wrapper page-wrapper h-100 {{ kioskMode ? 'kiosk-wrapper overflow-hidden' : '' }}">
  <div class="row justify-content-center gap-4 h-100" [class.kiosk-transparent]="kioskMode">
    <div class="col-lg-12 d-flex flex-column {{ kioskMode ? 'kiosk-height mt-0' : '' }}" [class.event]="isEventTicket">
      <div class="page-wrapper section-bg position-relative">
        <div class="d-flex flex-column-reverse flex-sm-row align-items-center justify-content-between w-100">
          <div class="row align-items-center w-100 px-2 px-sm-0 mt-5 mt-lg-0">
            <h1 class="mb-0 font-30 text-primary text-center fw-bold col-lg-6 offset-lg-3">
              {{ "SERVICES." + serviceCode | translate }}
            </h1>

            @if (isCompany) {
              <div class="page-wrapper py-0 text-end col-lg-3">
                <span class="d-flex align-items-center gap-2 font-12">
                  <h6 class="m-0 text-primary">{{ "FIELDS.DISCOUNT" | translate }}:</h6>

                  <span class="fw-bold">
                    {{ discountValue }}

                    @if (isPercentageDiscount) {
                      %
                    } @else {
                      {{ "OMR" | translate }}
                    }
                  </span>
                </span>
              </div>
            }
          </div>

          @if (kioskMode) {
            <app-button btnLabel="ACTIONS.BACK" btnClass="without-shadow px-4 h-35" (onClick)="back()" />
          }
        </div>

        <!-- rendered tickets forms -->
        <div class="mt-4">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
