import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-collapse-content",
  standalone: true,
  imports: [],
  templateUrl: "./collapse-content.component.html",
  styleUrl: "./collapse-content.component.scss",
})
export class CollapseContentComponent {
  showConetnt = false;

  @Input({ required: true }) title = "";
  @Input({ alias: "class" }) customClass = "";
  @Input() withBoxShadow = false;
  @Input() autoOpen = false;

  @Output() collapsed: EventEmitter<boolean> = new EventEmitter(); // if you want to do an event after collapsed

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    if (this.autoOpen) this.collapse();
  }

  collapse() {
    this.showConetnt = !this.showConetnt;
    this.showCollapsed();
    this.collapsed.emit(this.showConetnt);
  }

  private showCollapsed() {
    setTimeout(() => {
      const accordions = document.querySelectorAll(".accordion-wrapper");

      const openAccordion = (accordion: HTMLElement) => {
        accordion.style.height = accordion.scrollHeight + "px";
      };

      const closeAccordion = (accordion: HTMLElement) => {
        accordion.style.height = "0";
      };

      const observeContentChanges = (accordion: HTMLElement) => {
        const observer = new MutationObserver(() => {
          // Recalculate the height when content changes
          accordion.style.height = accordion.scrollHeight + "px";
        });

        observer.observe(accordion, {
          childList: true, // Observe direct child changes
          subtree: true, // Observe changes within descendants
          characterData: true, // Observe changes in text content
        });
      };

      accordions.forEach((accordion) => {
        const showContent = accordion.querySelector(".showContent") as HTMLElement;
        const content = accordion.querySelector(".contentCollapsed") as HTMLElement;

        if (content) {
          if (showContent) {
            openAccordion(content);
            observeContentChanges(content); // Start observing for content changes
          } else {
            closeAccordion(content);
          }
        }
      });
    });
  }
}
