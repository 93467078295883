export enum DateFormats {
  FULL_DATE_TIME = "dd/MM/yyyy - hh:mm a",
  dd_MM_yyyy_s = "dd/MM/yyyy",
  dd_MM_yyyy_d = "dd-MM-yyyy",
  yyyy_MM_dd_d = "yyyy-MM-dd",
  dateUTC = "yyyy-MM-dd'T'HH:mm:ss'Z'",
  dateTimezone = "yyyy-MM-dd'T'HH:mm:ssxxx",
  time = "HH:mm:ss",
  timezone = "hh:mm a",
  yyyy = "yyyy", // year
  MM = "MM", // month
  dd = "dd", // day
  HH = "HH", // hour 24
  mm = "mm", // minute
}
