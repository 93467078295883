import { HttpInterceptorFn } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
  const token = localStorage.getItem(environment.tokenPropertyName);

  req = req.clone({
    url: `${environment.DOMAIN_API}${req.url}`,
    setHeaders: {
      'Authorization': token ? `Bearer ${token}` : '',
      'Access-Control-Allow-Origin': '*',
      'Cache-Control': 'no-store, must-revalidate',
      'Pragma': 'no-cache',
      'Expires': '0',
    }
  });

  return next(req);
};
