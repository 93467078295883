import { RouterLink } from "@angular/router";
import { MatTabsModule } from "@angular/material/tabs";
import { TranslateModule } from "@ngx-translate/core";
import { AsyncPipe, NgComponentOutlet, NgTemplateOutlet } from "@angular/common";
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";

import { Tab } from "src/app/core/interfaces";
import { IsEnglishDirective } from "../../directives";

@Component({
  selector: "app-tabs",
  standalone: true,
  imports: [NgTemplateOutlet, NgComponentOutlet, AsyncPipe, RouterLink, MatTabsModule, TranslateModule, IsEnglishDirective],
  templateUrl: "./tabs.component.html",
  styleUrl: "./tabs.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class TabsComponent {
  @Input({ required: true }) tabs!: Tab[];
  @Input() selectedIndex = 0;
  @Input() removeInActiveTabContent = true;

  @Output() selectedIndexChange = new EventEmitter<number>();

  isEnglish = false;
}
