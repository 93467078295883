<div class="mat-mdc-form-field-wrapper form-group d-flex flex-column m-0" [class.mb-3]="control.errors && control.touched">
  <mat-form-field class="d-block" [appearance]="appearance" #matFormField>
    <mat-label class="text-middle font-14">
      {{ label | translate }}
    </mat-label>

    <div class="d-flex align-items-center w-100">
      <input
        matInput
        class="mat-mdc-input-dropdown-element"
        [type]="contentType"
        [placeholder]="placeholder | translate"
        [formControl]="control"
        (keydown)="control!.markAsTouched()"
        (keypress)="onKeyPress($event)"
        (keyup.enter)="inputValue.emit(control!.value)"
      />

      <mat-select
        class="w-30"
        [compareWith]="compareWithFn"
        [formControl]="dropdownControl"
        (selectionChange)="getSelectedValue($event)"
        (openedChange)="filterControl.setValue(null)"
        (click)="$event.stopPropagation()"
      >
        @if (withSearch) {
          <input
            class="mat-mdc-select-search-input w-100 px-3"
            type="text"
            [formControl]="filterControl"
            [placeholder]="'ACTIONS.SEARCH' | translate"
          />
        }

        <!-- <mat-option class="reset" [value]="null">--</mat-option> -->

        @for (option of filteredOptions; track option) {
          <mat-option [value]="option[bindValue]" [disabled]="option.disabled">
            {{ option | localizedName }}
          </mat-option>
        }
      </mat-select>
    </div>

    @if (control.errors) {
      <mat-error [class]="matErrorClass">
        <span class="font-12">
          {{ control.errors | validationHandler }}
        </span>
      </mat-error>
    }

    @if (dropdownControl.errors) {
      <mat-hint class="text-danger">
        <span class="font-12">
          {{ dropdownControl.errors | validationHandler }}
        </span>
      </mat-hint>
    }
  </mat-form-field>
</div>
