import { Routes } from "@angular/router";

import { companiesListRoutes, companiesManagementRoutes } from "./nested-features/companies";

export const usersManagementRoutes: Routes = [
  {
    path: "",
    title: "USERS.USERS_MANAGEMENT",
    data: {
      tabs: [
        {
          key: "Companies",
          title: "COMPANIES.TITLE",
          routerLink: "companies",
        },
      ],
    },
    loadComponent: () =>
      import("src/app/shared/base-components/base-tabs-management/base-tabs-management.component").then(
        (c) => c.BaseTabsManagementComponent,
      ),
    children: [
      { path: "", redirectTo: "companies", pathMatch: "full" },
      {
        path: "companies",
        children: companiesListRoutes,
      },
    ],
  },
  {
    path: "companies",
    children: companiesManagementRoutes,
  },
];
