import { TranslateModule } from "@ngx-translate/core";
import { Component, inject } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";

import { AuthService, mustMatch } from "src/app/core";
import { InputTextComponent, ButtonComponent, DatepickerComponent } from "src/app/shared";

@Component({
  selector: "app-register",
  standalone: true,
  imports: [RouterLink, TranslateModule, ReactiveFormsModule, InputTextComponent, ButtonComponent, DatepickerComponent],
  templateUrl: "./register.component.html",
  styleUrl: "./register.component.scss",
})
export class RegisterComponent {
  private fb = inject(FormBuilder);
  private router = inject(Router);
  private authService = inject(AuthService);

  registerForm!: FormGroup;
  showPassword = false;
  today = new Date();

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.initForm();
  }

  initForm() {
    this.registerForm = this.fb.group(
      {
        name: [null, [Validators.required]],
        email: [null, [Validators.required, Validators.email]],
        phone: [null, [Validators.required, Validators.minLength(8), Validators.maxLength(8)]],
        gender: [1],
        employmentType: [1],
        isStudent: [false],
        isOmanResident: [true],
        nationalityId: 1,
        countryId: 1,
        governorateId: 4,
        stateId: 14,
        password: [null, [Validators.required, Validators.minLength(8)]],
        confirmPassword: [null, [Validators.required, Validators.minLength(8)]],
        dateOfBirth: [null, [Validators.required]],
      },
      {
        validators: mustMatch("password", "confirmPassword"),
      },
    );
  }

  register() {
    this.authService.register(this.registerForm.value).subscribe({
      next: (data) => {
        localStorage.setItem("username", this.registerForm.value.email);
        localStorage.setItem("phone", this.registerForm.value.phone);

        if (data["code"] === 1) {
          this.router.navigateByUrl("/auth/verify");
        } else {
          this.router.navigateByUrl("/auth/login");
        }
      },
    });
  }
}
