import { TranslateModule } from "@ngx-translate/core";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { Component, TemplateRef, ViewChild, inject } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";

import { AuthService, Tab } from "src/app/core";
import { InputTextComponent, ButtonComponent, TabsComponent } from "src/app/shared";

@Component({
  selector: "app-forget-password",
  standalone: true,
  imports: [RouterLink, TranslateModule, ReactiveFormsModule, InputTextComponent, ButtonComponent, TabsComponent],
  templateUrl: "./forget-password.component.html",
  styleUrl: "./forget-password.component.scss",
})
export class ForgetPasswordComponent {
  private fb = inject(FormBuilder);
  private router = inject(Router);
  private authService = inject(AuthService);
  private activatedRoute = inject(ActivatedRoute);

  forgetPasswordForm!: FormGroup;
  forgetPasswordTabs: Tab[] = [];

  @ViewChild("byEmailTab", { static: true }) byEmailTab!: TemplateRef<unknown>;
  @ViewChild("byPhoneTab", { static: true }) byPhoneTab!: TemplateRef<unknown>;

  get isForgetByPhone() {
    return this.forgetPasswordForm.get("type")?.value === 2;
  }

  get token() {
    return this.activatedRoute.snapshot.queryParamMap.get("token") || null;
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    if (this.token) {
      this.router.navigate(["email"], { relativeTo: this.activatedRoute, queryParamsHandling: "merge" });
    }

    this.forgetPasswordTabs = [
      {
        key: "email",
        title: "AUTH.BYEMAIL",
        template: this.byEmailTab,
      },
      {
        key: "phone",
        title: "AUTH.BYMOBILE",
        template: this.byPhoneTab,
      },
    ];

    this.initForm();
  }

  initForm() {
    this.forgetPasswordForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      phone: [{ value: "", disabled: true }, [Validators.required]],
      type: [1],
    });
  }

  getActiveTab(event: number) {
    switch (event) {
      case 0:
        this.forgetPasswordForm.get("type")?.setValue(1);
        this.forgetPasswordForm.get("phone")?.disable();
        this.forgetPasswordForm.get("email")?.enable();
        break;
      case 1:
        this.forgetPasswordForm.get("type")?.setValue(2);
        this.forgetPasswordForm.get("email")?.disable();
        this.forgetPasswordForm.get("phone")?.enable();
        break;
      default:
        break;
    }
  }

  forget() {
    this.authService.resetPassword(this.forgetPasswordForm.value).subscribe(() => {
      if (this.isForgetByPhone) {
        localStorage.setItem("phone", this.forgetPasswordForm.value.phone);
        this.router.navigate(["otp"], { relativeTo: this.activatedRoute });
        return;
      } else {
        this.router.navigate(["/auth/login"]);
      }
    });
  }
}
