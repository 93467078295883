import { AsyncPipe } from "@angular/common";
import { MatListModule } from "@angular/material/list";
import { TranslateModule } from "@ngx-translate/core";
import { Component, Input, inject } from "@angular/core";
import { RouterLink, RouterLinkActive } from "@angular/router";

import { UserRole } from "../../enums";
import { SidebarItem } from "../../interfaces";
import { HttpService, SidebarToggleService } from "../../services";

@Component({
  selector: "app-sidebar-list-item",
  standalone: true,
  imports: [AsyncPipe, RouterLink, RouterLinkActive, MatListModule, TranslateModule],
  templateUrl: "./sidebar-list-item.component.html",
  styleUrl: "./sidebar-list-item.component.scss",
})
export class SidebarListItemComponent {
  isSidebarOpened$ = inject(SidebarToggleService).isSidebarOpened$;
  roleCode = inject(HttpService).convertTokenJWT()?.RoleCode as UserRole;
  showContent = false;

  @Input() sidebarItem!: SidebarItem;

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

    if (this.roleCode !== UserRole.CUSTOMER) (this.showContent = true), this.showCollapsed();
  }

  collapse(children: SidebarItem[] | undefined) {
    if (children) (this.showContent = !this.showContent), this.showCollapsed();
  }

  private showCollapsed() {
    setTimeout(() => {
      const accordions = document.querySelectorAll(".collapse-wrapper");

      const openAccordion = (accordion: any) => {
        accordion.style.height = accordion.scrollHeight + "px";
      };

      const closeAccordion = (accordion: any) => {
        accordion.style.height = "0";
      };

      accordions.forEach((accordion) => {
        const showContent = accordion.querySelector(".showContent");
        const content = accordion.querySelector(".nested-items");

        if (showContent) {
          openAccordion(showContent);
        } else {
          if (content) closeAccordion(content);
        }
      });
    });
  }
}
