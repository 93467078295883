import { Injectable } from "@angular/core";

import { GuidedTourPayload, GuidedTourProgram } from "../../interfaces";

import { HttpService, HttpServiceBaseCRUD, GetPagedBody, GetPagedResponse } from "src/app/core";

@Injectable({
  providedIn: "root",
})
export class GuidedToursProgramsService extends HttpService implements HttpServiceBaseCRUD {
  protected override get baseUrl(): string {
    return "programs/";
  }

  add(body: GuidedTourPayload) {
    return this.post<GuidedTourProgram>({ APIName: "add", body, showAlert: true });
  }

  update(body: GuidedTourPayload) {
    return this.put<GuidedTourProgram>({ APIName: "update", body, showAlert: true });
  }

  remove(id: string) {
    return this.delete<boolean>({ APIName: `deletesoft/${id}`, showAlert: true });
  }

  getById(id: string) {
    return this.get<GuidedTourProgram>({ APIName: `get-id/${id}` });
  }

  getAll() {
    return this.get<GuidedTourProgram[]>({ APIName: "getall" });
  }

  getPaged(
    body: GetPagedBody<Partial<GuidedTourProgram>> = {
      pageNumber: 1,
      pageSize: 10,
      orderByValue: [{ colId: "id", sort: "asc" }],
      filter: {},
    },
  ) {
    return this.post<GetPagedResponse<GuidedTourProgram[]>>({ APIName: "getpaged", body });
  }
}
