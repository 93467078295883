<div class="accordion-wrapper {{ customClass }}" [class.default]="withBoxShadow">
  <div class="accordion-conetnt d-flex align-items-center justify-content-end w-100 position-relative" (click)="collapse()">
    <span class="title text-truncate text-capitalize text-primary font-14 position-absolute" [class.active]="showConetnt">
      {{ title }}
    </span>

    <svg
      class="{{ showConetnt ? 'animate active' : '' }}"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fill-rule="evenodd"
        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
        clip-rule="evenodd"
      />
    </svg>
  </div>

  @if (showConetnt) {
    <div class="accordion-separetor mt-1" [class.active]="showConetnt"></div>
  }

  <div class="contentCollapsed" [class.showContent]="showConetnt">
    <ng-content></ng-content>
  </div>
</div>
