import { Injectable } from "@angular/core";
import { MatStepper } from "@angular/material/stepper";

@Injectable({
  providedIn: "root",
})
export class StepperService {
  private _stepper!: MatStepper;

  set stepper(stepper: MatStepper) {
    this._stepper = stepper;
  }

  get stepper() {
    return this._stepper;
  }

  previous() {
    this._stepper.previous();
  }

  next() {
    this._stepper.next();
  }
}
