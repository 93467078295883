import { debounceTime } from "rxjs";
import { TranslateModule } from "@ngx-translate/core";
import { MatSelect, MatSelectModule } from "@angular/material/select";
import { MatFormField, MatInputModule } from "@angular/material/input";
import { takeUntilDestroyed, toSignal } from "@angular/core/rxjs-interop";
import { FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import {
  Component,
  effect,
  EventEmitter,
  forwardRef,
  HostListener,
  inject,
  Injector,
  Input,
  Output,
  runInInjectionContext,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";

import { ReactiveFormsBaseComponent } from "../../base-components";
import { ValidationHandlerPipe, LocalizedNamePipe } from "../../pipes";

import { Dropdown } from "src/app/core";

@Component({
  selector: "app-input-text-dropdown",
  standalone: true,
  imports: [ReactiveFormsModule, MatInputModule, MatSelectModule, TranslateModule, ValidationHandlerPipe, LocalizedNamePipe],
  templateUrl: "./input-text-dropdown.component.html",
  styleUrl: "./input-text-dropdown.component.scss",
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTextDropdownComponent),
      multi: true,
    },
  ],
})
export class InputTextDropdownComponent extends ReactiveFormsBaseComponent {
  private injector = inject(Injector);

  @Input({ required: true }) label = "";
  @Input() matErrorClass = "";
  @Input() placeholder = "";
  @Input() contentType: "text" | "number" = "number";

  @Input({ required: true }) dropdownFormControlName!: string;
  @Input({ required: true }) options!: Dropdown[];
  @Input() withSearch = false;
  @Input() bindValue: keyof Dropdown = "id";

  @Output() inputValue = new EventEmitter<string>();
  @Output() selected = new EventEmitter<number | string>();

  @ViewChild("matFormField") matFormField!: MatFormField;

  filterControl = new FormControl<string | null>(null);
  filteredOptions: Dropdown[] = [];

  get dropdownControl() {
    return this.form.get(this.dropdownFormControlName) as FormControl;
  }

  get dropdownValue() {
    if (!this.dropdownControl) return null;
    return this.dropdownControl.value;
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    runInInjectionContext(this.injector, () => {
      this.handleDropdownControlSearch();
      this.handleDropdownControlErrorState();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes["options"]) this.filteredOptions = changes["options"].currentValue;
  }

  onKeyPress(event: KeyboardEvent): void {
    if (this.contentType === "number" && ["e", "+", "-"].includes(event.key.toLowerCase())) {
      event.preventDefault();
    }
  }

  getSelectedValue(event: { source: MatSelect; value: Dropdown["id"] }) {
    this.selected.emit(event.value);
  }

  compareWithFn = (valueOption: number, dropdownOption: number) => {
    return valueOption == dropdownOption;
  };

  private handleDropdownControlSearch() {
    if (this.withSearch) {
      this.filterControl.valueChanges.pipe(debounceTime(500), takeUntilDestroyed()).subscribe((searchKey) => {
        if (!searchKey?.length) {
          this.filteredOptions = this.options;
          return;
        }

        this.filteredOptions = this.options.filter(
          (each) =>
            each.nameEn.toLowerCase().includes(searchKey.toLowerCase()) || each.nameAr.toLowerCase().includes(searchKey.toLowerCase()),
        );
      });
    }
  }

  private handleDropdownControlErrorState() {
    const dropdownControlStatusChanges = toSignal(this.dropdownControl?.statusChanges);
    const InputTextControlValueChanges = toSignal(this.control?.valueChanges);

    effect(() => {
      const elementRef: HTMLElement = this.matFormField._elementRef.nativeElement;
      const isDropdownControlInValid = !dropdownControlStatusChanges() || dropdownControlStatusChanges() === "INVALID";

      if (InputTextControlValueChanges() && isDropdownControlInValid && !this.dropdownControl.value) {
        elementRef.querySelector(".mat-mdc-text-field-wrapper")?.classList.add("mdc-text-field--invalid");
        if (!this.dropdownControl.value) this.dropdownControl.setErrors({ required: true });
        this.dropdownControl.markAsTouched();
      } else {
        elementRef.querySelector(".mat-mdc-text-field-wrapper")?.classList.remove("mdc-text-field--invalid");
      }
    });
  }

  @HostListener("paste", ["$event"]) onPaste(event: ClipboardEvent): void {
    if (this.contentType === "number" && ["e", "+", "-"].includes(event.clipboardData?.getData("text/plain").toLowerCase()!)) {
      event.preventDefault();
    }
  }
}
