import { NgClass, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-button",
  standalone: true,
  imports: [NgClass, NgIf, MatButtonModule, TranslateModule],
  templateUrl: "./button.component.html",
  styleUrl: "./button.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class ButtonComponent {
  @Input() btnLabel!: string;
  @Input() btnClass!: string;
  @Input() type: "submit" | "button" = "button";
  @Input() disabled = false;
  @Input() iconPosition: "right" | "left" = "right";
  @Input() color: "primary" | "accent" | "warn" | null = null;

  @Output() onClick = new EventEmitter();
  @Output() onHover = new EventEmitter();
}
