import { AbstractControl, FormControl } from "@angular/forms";

export const mustMatch = (controlName: string, matchingControlName: string) => {
  return (formGroup: AbstractControl) => {
    const control = formGroup.get(controlName) as FormControl;
    const matchingControl = formGroup.get(matchingControlName) as FormControl;

    if (matchingControl.errors && !matchingControl.getError("confirmPassword")) {
      // return if another validator has already found an error on the matchingControl
      return null;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ confirmPassword: true });
    } else {
      matchingControl.setErrors(null);
    }

    return null;
  };
};
