import { Injectable } from "@angular/core";
import saveAs from "file-saver";
import * as XLSX from "xlsx";

const EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

@Injectable({
  providedIn: "root",
})
export class ExportService {
  excel<T extends {}>(json: T[], excelFileName: string): void {
    const worksheet = XLSX.utils.json_to_sheet(json);
    // Calculate column widths based on content
    const columnWidths = this.calculateColumnWidths(json);
    worksheet["!cols"] = columnWidths.map((width) => ({ width }));

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "exported-data");

    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    this.saveAs(excelBuffer, `${excelFileName}_export_${new Date().getTime()}${EXCEL_EXTENSION}`, EXCEL_TYPE);
  }

  pdf(data: Blob, fileName: string): void {
    this.saveAs(data, `${fileName}.pdf`, "application/pdf");
  }

  /**
   * @param data `Record<string, unknown>[]`
   * @returns `number[]`
   * @description Calculate column widths based on content
   */
  private calculateColumnWidths<T extends Record<string, unknown>>(data: T[]): number[] {
    let columnWidths: number[] = [];

    data.forEach((row) => {
      Object.keys(row).forEach((key, index) => {
        const columnLength = String(row[key]).length;

        columnWidths[index] = Math.max(columnWidths[index] || 0, columnLength * 1.2); // Adjust the multiplier for a better fit
      });
    });

    return columnWidths;
  }

  /**
   *
   * @param buffer `BlobPart`
   * @param fileName `string`
   * @param type `string`
   * @description Save the file as a blob
   */
  private saveAs(buffer: BlobPart, fileName: string, type: string): void {
    const data: Blob = new Blob([buffer], { type });
    // let url = window.URL.createObjectURL(data);
    saveAs(data, fileName);
  }
}
