<mat-form-field class="timepicker">
  <input
    matInput
    [format]="format"
    [formControl]="control"
    [max]="maxTime"
    [min]="minTime"
    [ngxMatTimepicker]="timePicker"
    placeholder="hh:mm"
    readonly
  />
  <ngx-mat-timepicker-toggle matSuffix class="text-primary" (click)="timePicker.open()"></ngx-mat-timepicker-toggle>
</mat-form-field>

<ngx-mat-timepicker color="primary" [appendToInput]="true" #timePicker></ngx-mat-timepicker>
