import { Injectable } from "@angular/core";

import { Company, CompanyPayload } from "../../interfaces";

import { GetPagedBody, GetPagedResponse, HttpService, HttpServiceBaseCRUD } from "src/app/core";

@Injectable({
  providedIn: "root",
})
export class CompaniesService extends HttpService implements HttpServiceBaseCRUD {
  protected override get baseUrl(): string {
    return "company/";
  }

  add(body: CompanyPayload) {
    return this.post<Company>({ APIName: "add", body, showAlert: true });
  }

  update(body: CompanyPayload) {
    return this.put<Company>({ APIName: "update", body, showAlert: true });
  }

  remove(id: string) {
    return this.delete<boolean>({ APIName: `deletesoft/${id}`, showAlert: true });
  }

  getById(id: string) {
    return this.get<Company>({ APIName: `get/${id}` });
  }

  getAll() {
    return this.get<Company[]>({ APIName: "getall" });
  }

  getPaged(body: GetPagedBody<Company>) {
    return this.post<GetPagedResponse<Company[]>>({ APIName: "getpaged", body });
  }
}
