import { Component, ViewEncapsulation, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { TranslateModule } from "@ngx-translate/core";
import { ActivatedRoute, NavigationEnd, Router, RouterLink, RouterOutlet } from "@angular/router";
import { filter } from "rxjs";

import { TranslationService } from "src/app/core";
import { ButtonComponent } from "src/app/shared";

@Component({
  selector: "app-auth",
  standalone: true,
  imports: [RouterLink, RouterOutlet, TranslateModule, ButtonComponent],
  templateUrl: "./auth.component.html",
  styleUrl: "./auth.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class AuthComponent {
  private translateService = inject(TranslationService);
  private activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);

  pageType!: string;

  get isSignIn() {
    return this.pageType === "SIGNIN";
  }

  get isRegister() {
    return this.pageType === "REGISTER";
  }

  constructor() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntilDestroyed(),
      )
      .subscribe(() => {
        const type = this.activatedRoute.firstChild?.snapshot.data["type"];
        this.pageType = type ? type : this.activatedRoute.firstChild?.firstChild?.snapshot.data["type"];
      });
  }

  changeLanguage() {
    this.translateService.changeLanguage();
  }
}
