<swiper-container appSwiper [config]="defaultConfig()" init="false" class="{{ containerClass() }}" [dir]="dir()">
  @for (slide of slides(); track $index) {
    <swiper-slide [class]="slideClass()">
      @if (slideTemplateRef) {
        <ng-template [ngTemplateOutlet]="slideTemplateRef" [ngTemplateOutletContext]="{ $implicit: slide }"></ng-template>
      }
    </swiper-slide>
  }
</swiper-container>

<!-- 
  Usage: 
    * You can use this component in any other component by passing the slides and config as inputs.
    * You can access the slide data using the `let-slide` directive on the `ng-template`.
      * whatever you pass to the `slides` input will each slide be available in the `let-slide` directive.

  HTML:
    `
      <swiper [slides]="images" [config]="swiperConfig" [slideTemplateRef]="swiperSlide">
        <ng-template #swiperSlide let-slide>
          <img
            class="d-block w-100 product-thumbnail-image img-fluid"
            [src]="slide"
          />
        </ng-template>
      </swiper>
    `
  
  TS:
    `
      swiperConfig: SwiperOptions = {
        slidesPerView: 3,
      };

      images: string[] = [
        "https://images.unsplash.com/photo-1530076886461-ce58ea8abe24?q=80&w=1771&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1526779259212-939e64788e3c?q=80&w=1774&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1530076886461-ce58ea8abe24?q=80&w=1771&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      ];
    `
 -->
