import { Injectable } from "@angular/core";
import { format, addMinutes } from "date-fns";
import { DateFormats } from "../../enums";

@Injectable({
  providedIn: "root",
})
export class DateService {
  utc(dateValue: Date | null) {
    if (!dateValue) throw new Error("The date must be pased.");

    const finalDate = this.finalDate(dateValue);
    return format(addMinutes(finalDate, finalDate.getTimezoneOffset() + 1), DateFormats.dateUTC);
  }

  get today() {
    return this.finalDate(new Date());
  }

  get todayAtMidnight() {
    return this.finalDate(new Date(), true);
  }

  private finalDate(date: Date, isMidnight = false) {
    const dateYear = +format(date, DateFormats.yyyy),
      dateMonth = +format(date, DateFormats.MM) - 1,
      dateDay = +format(date, DateFormats.dd),
      dateHours = isMidnight ? 0 : +format(new Date(), DateFormats.HH),
      dateMinutes = isMidnight ? 0 : +format(new Date(), DateFormats.mm);

    return new Date(dateYear, dateMonth, dateDay, dateHours, dateMinutes);
  }
}
