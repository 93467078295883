<aside class="sidebar-wrapper bg-primary d-flex flex-column h-100" [class.opened]="isSidebarOpened$ | async">
  <div class="sidebar-content overflow-auto w-100 h-100">
    <mat-list class="d-flex flex-column p-0" role="list">
      @for (sidebarItem of sidebarItems; track $index) {
        @if (sidebarItem.permissions && sidebarItem.permissions.includes(roleCode)) {
          <mat-list-item role="listitem">
            <app-sidebar-list-item [sidebarItem]="sidebarItem" />
          </mat-list-item>
        }
      }
    </mat-list>
  </div>
</aside>
