import { TranslateModule } from "@ngx-translate/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { MatCheckboxChange, MatCheckboxModule } from "@angular/material/checkbox";
import { Component, EventEmitter, Input, Output, ViewEncapsulation, forwardRef } from "@angular/core";

import { LocalizedNamePipe } from "../../pipes";
import { ReactiveFormsBaseComponent } from "../../base-components";

import { Checkbox } from "src/app/core/interfaces";

@Component({
  selector: "app-checkbox",
  standalone: true,
  imports: [MatCheckboxModule, TranslateModule, LocalizedNamePipe],
  templateUrl: "./checkbox.component.html",
  styleUrl: "./checkbox.component.scss",
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent extends ReactiveFormsBaseComponent {
  @Input({ required: true }) label!: string;
  @Input() checked = false;
  @Input() options: Checkbox[] = [];
  @Input() bindValue: keyof Checkbox = "id";

  @Output() isChecked: EventEmitter<boolean | Checkbox[]> = new EventEmitter();

  change(event: MatCheckboxChange, option?: Checkbox) {
    if (this.options.length) {
      option!.checked = event.checked;
      const checkedOptions = this.options.filter((each) => each.checked);

      this.control.setValue(checkedOptions.map((checkedOption) => checkedOption[this.bindValue]));
      this.isChecked.emit(checkedOptions);
    } else {
      this.control.setValue(event.checked);
      this.checked = event.checked;
      this.isChecked.emit(event.checked);
    }
  }
}
