import { tap } from "rxjs";
import { Router } from "@angular/router";
import { inject, Injectable } from "@angular/core";

import { UserRole } from "../../enums";
import { HttpService } from "../http/http.service";
import { GetPagedBody, GetPagedResponse, ReservationPayload, ReservationResponse } from "../../interfaces";

@Injectable({
  providedIn: "root",
})
export class ReservationService extends HttpService {
  private router = inject(Router);
  private user = this.convertTokenJWT();

  protected override get baseUrl(): string {
    return "reservation/";
  }

  get isGuest() {
    return this.user?.RoleCode === UserRole.GUEST;
  }

  get isAdmin() {
    return this.user?.RoleCode === UserRole.ADMIN;
  }

  addReservation(body: Record<string, unknown>) {
    return this.post({ APIName: "add-reservation", body }).pipe(
      tap((reservationId) => {
        if (this.isGuest) {
          this.router.navigate([`/ticket/${reservationId}`]);
          return;
        }

        if (this.isAdmin) {
          this.router.navigate([`/admin/reservations/view/${reservationId}`]);
          return;
        }

        this.router.navigate([`/my-tickets/${reservationId}`]);
      }),
    );
  }

  getReservation(reservationNumber: string | number) {
    return this.get<ReservationResponse>({ APIName: `reservation/${reservationNumber}` });
  }

  getMyReservations(body: GetPagedBody<Partial<ReservationPayload>>) {
    const filter = body.filter || {};
    body.filter = { ...filter, allowAll: filter.allowAll || false };

    return this.post<GetPagedResponse<ReservationResponse[]>>({ APIName: "my-reservation", body });
  }

  getReservationByTicketNumber(ticketNumber: string | number) {
    return this.get<ReservationResponse>({ APIName: `ticket/${ticketNumber}` });
  }

  getReservedSeats(eventId: number) {
    return this.get({ APIName: `GetReservedSeats/${eventId}` });
  }

  getHallReservedDays(body: Record<string, unknown>) {
    return this.post({ APIName: "GetHallReservedDays", body });
  }

  reissue(body: Record<string, unknown>) {
    return this.post({ APIName: "reissue-reservation", body });
  }

  confrimReservation(reservationId: string | number) {
    return this.get({ APIName: `ConfirmHallReservation/${reservationId}` });
  }

  download(ticketNumber: string | number, templateType: "old" | "new" = "old") {
    const template = templateType === "old" ? "EventPrinterTicket" : "EventTicket";
    return this.get<Blob>({ APIName: `${ticketNumber}/${template}`, responseType: "blob" as "json" });
  }

  print(ticketNumber: string | number) {
    return this.get({ APIName: `print/${ticketNumber}` });
  }
}
