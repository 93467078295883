import { Directive, Input } from "@angular/core";
import { AbstractControl, FormControl, NG_VALIDATORS, ValidationErrors } from "@angular/forms";

import { Languages } from "src/app/core/enums";

@Directive({
  selector: "[specificLanguage]",
  standalone: true,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: SpecificLanguageValidatorDirective,
      multi: true,
    },
  ],
})
export class SpecificLanguageValidatorDirective {
  @Input() specificLanguage: string | null = null;

  validate(control: FormControl) {
    if (!control.value || typeof control.value !== "string") {
      return null;
    }

    return this.validateLanguageFactory(control);
  }

  private validateLanguageFactory(control: AbstractControl) {
    const isValid = this.validateLanguage(control.value);

    if (isValid) return null;

    return this.generateEroor();
  }

  private validateLanguage(controlValue: string): boolean {
    let rgex: RegExp = /^/;

    switch (this.specificLanguage) {
      case Languages.AR:
        rgex = /^[\u0600-\u06FF0-9 ~_?-`!@#$%^&*()+=|;':",.\/\\]*$/; // arabic caracters with spaces and numbers
        break;
      case Languages.EN:
        rgex = /^[a-zA-Z0-9 _?-~`!@#$%^&*()+=|;':",.\/\\]*$/; // english caracters with spaces and numbers
        break;
      default:
        break;
    }

    return rgex.test(controlValue.toLowerCase());
  }

  private generateEroor(): ValidationErrors | null {
    if (this.specificLanguage === Languages.AR) {
      return { arabicWord: true };
    }

    return { englishWord: true };
  }
}
