export enum UserRole {
  ADMIN = "ADMIN",
  EVENTCREATOR = "EVENT-CREATOR",
  EVENTAUDITOR = "EVENT-AUDITOR",
  EVENTAPPROVER = "EVENT-APPROVER",
  COUNTER = "COUNTER",
  CUSTOMER = "CUSTOMER",
  HALLAPPROVER = "HALL-APPROVER",
  COMPANY = "COMPANY",
  KIOSK = "KIOSK",
  GUEST = "GUEST",
  STATISTICUSER = "STATISTIC-USER",
  FINANCIALUSER = "FINANCIAL-USER",
}
