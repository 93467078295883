import { Routes } from "@angular/router";

export const companiesListRoutes: Routes = [
  {
    path: "",
    title: "COMPANIES.TITLE",
    loadComponent: () => import("./pages/companies-list/companies-list.component").then((m) => m.CompaniesListComponent),
  },
];

export const companiesManagementRoutes: Routes = [
  // {
  //   path: "add",
  //   title: "COMPANIES.ADD",
  //   loadComponent: () => import("./pages/add-edit-company/add-edit-company.component").then((m) => m.AddEditCompanyComponent),
  // },
  {
    path: "edit/:id",
    title: "COMPANIES.EDIT",
    loadComponent: () => import("./pages/add-edit-company/add-edit-company.component").then((m) => m.AddEditCompanyComponent),
  },
];
