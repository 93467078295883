import { ToastrService } from "ngx-toastr";
import { Injectable, inject } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { HttpTransportType, HubConnection, HubConnectionBuilder } from "@microsoft/signalr";

import { environment } from "../../../../environments/environment";
import { HttpService, MenuItem, TranslationService } from "../..";

@Injectable({
  providedIn: "root",
})
export class NotificationsService extends HttpService {
  private alert = inject(ToastrService);
  private isEnglish = inject(TranslationService).isEnglish;

  connection!: HubConnection | null;
  connectionEstablished = new Subject<boolean>();
  notifications: MenuItem[] = [];
  notificationCounter = 0;

  protected get baseUrl(): string {
    return "Notifications/";
  }

  connect(): void {
    this.startConnection();
  }

  startConnection = () => {
    this.connection = new HubConnectionBuilder()
      .withUrl(`${environment.DOMAIN}hubs/bookingnotification`, {
        transport: HttpTransportType.WebSockets,
        accessTokenFactory: () => localStorage.getItem(environment.tokenPropertyName) as string,
      })
      .withAutomaticReconnect(this.delays)
      .build();

    this.connection
      .start()
      .then(() => {
        this.AddListeners();
      })
      .catch((err) => {
        console.error(`Error while starting connection: ${err}`);
      });
  };

  getAll(): void {
    this.get<MenuItem[]>({ APIName: "GetAll" }).subscribe((res) => {
      this.notifications = [];
      setTimeout(() => (this.notifications = res));
    });
  }

  disconnect(): void {
    if (this.connection) {
      this.connection.stop();
      this.connection = null;
    }
  }

  getCount(): void {
    this.get<number>({ APIName: "GetCount" }).subscribe((notificationCounter) => (this.notificationCounter = notificationCounter));
  }

  setIsRead(notificationId: string): Observable<any> {
    return this.get({ APIName: `SetIsRead/${notificationId}` });
  }

  /**
   * Private Methods
   */
  private AddListeners(): void {
    if (!this.connection) return;

    this.connection.on("NewNotification", (data: any) => {
      const notification = JSON.parse(data);
      this.alert.success(this.isEnglish ? notification?.TitleEn : notification?.TitleAr);
      this.getCount();
      this.getAll();
    });

    this.connection.onreconnected(() => {
      this.getCount();
      this.getAll();
    });

    // this.connection.onclose(() => {
    //   setTimeout(() => {
    //     this.connect();
    //   }, 60000);
    // });
  }

  get delays(): number[] {
    const intervals = environment.signalR.intervals;
    const interval = environment.signalR.interval;
    const numbers: number[] = [0];

    let initValue = 0;

    for (let i = 0; i <= intervals; i++) {
      initValue = initValue + interval;
      numbers.push(initValue);
    }

    return numbers;
  }
}
