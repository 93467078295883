import { Component, inject } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { LoadingComponent, LoadingService, ThemeService } from "./core";

@Component({
  selector: "app-root",
  standalone: true,
  imports: [RouterOutlet, LoadingComponent],
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss",
})
export class AppComponent {
  private themeService = inject(ThemeService);
  private loadingService = inject(LoadingService);

  isLoading = true;

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.loadingService.isLoading$.subscribe((isLoading) => setTimeout(() => (this.isLoading = isLoading)));
  }
}
