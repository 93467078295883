import { map } from "rxjs";
import { toSignal } from "@angular/core/rxjs-interop";
import { BreakpointObserver } from "@angular/cdk/layout";
import { Directive, effect, inject, Injector, input, output, runInInjectionContext } from "@angular/core";

@Directive({
  selector: "[breakpoints]",
  standalone: true,
})
export class BreakpointCheckerDirective {
  private injector = inject(Injector);
  private breakpointObserver = inject(BreakpointObserver);

  breakpoints = input.required<string[]>();
  breakpointsStates = output<Record<string, boolean> | undefined>();

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

    runInInjectionContext(this.injector, () => {
      const layoutChanges = toSignal(
        this.breakpointObserver.observe(this.breakpoints()).pipe(map((breakpointsStates) => breakpointsStates.breakpoints)),
      );

      effect(() => this.breakpointsStates.emit(layoutChanges()));
    });
  }
}

/**
 * Usage:
 *  - Add the directive to the `imports` array of the component.
 *  - Add the directive to the HTML element.
 *  - Add the breakpoints array to the component.
 *  - Implement the `getBreakpointsStates` method in the component.
 *  - Use the breakpoint states in the component.
 *  - The directive will emit the breakpoint states to the `breakpointsStates` output.
 *  - The directive will observe the breakpoints and emit the states to the output.
 *
 *
 *  HTML:
 *    <div [breakpoints]="breakpoints" (breakpointsStates)="getBreakpointsStates($event)">
 *      <!-- content here -->
 *    </div>
 *
 *  TS:
 *    import { BreakpointCheckerDirective } from "src/app/shared";
 *
 *    @Component({
 *      ...,
 *      standalone: true,
 *      imports: [BreakpointCheckerDirective],
 *    })
 *    export class XyzComponent {
 *      breakpoints = ["(min-width: 960px)"];
 *      isNormalScreen = false;
 *
 *      getBreakpointsStates(layoutStates: Record<string, boolean> | undefined) {
 *        this.isNormalScreen = layoutStates!["(min-width: 960px)"];
 *      }
 *    }
 */
