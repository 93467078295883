import { Pipe, PipeTransform, inject } from "@angular/core";
import { ValidationErrors } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
  name: "validationHandler",
  standalone: true,
  pure: false,
})
export class ValidationHandlerPipe implements PipeTransform {
  private translate = inject(TranslateService);

  transform(value: ValidationErrors | null): string {
    if (!value) return "";

    let customMessage = "";

    const validator = Object.keys(value)[0];

    if (value[validator].requiredLength) {
      customMessage = value[validator].requiredLength;
    } else if (["max", "min"].includes(validator)) {
      customMessage = value[validator][validator]; // value.max.max OR value.min.min;;
    } else if (validator === "allowedTypes") {
      const listFormat = new Intl.ListFormat(this.translate.currentLang);
      customMessage = listFormat.format(value["allowedTypes"]); // a, b, and c.
    }

    // the `validator` property should be camelCase.
    // add the error message inside the validators object using the error property.
    return this.translate.instant(`FORM_VALIDATION.${validator}`, {
      value: customMessage ? `(${customMessage})` : "",
    });
  }
}
