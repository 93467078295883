import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, inject } from "@angular/core";
import { ValidationErrors } from "@angular/forms";
import { Observable } from "rxjs";
import { NgClass, NgIf, NgFor, UpperCasePipe } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";

import { Attachment, AttachmentService } from "src/app/core";
import { ReactiveFormsBaseComponent } from "../../base-components";
import { ValidationHandlerPipe } from "../../pipes";
import { TranslateModule } from "@ngx-translate/core";

interface File {
  fileName: string;
  size: null;
  attachmentDisplaySize: any;
  url: string;
  extension: string;
  isSuccess: boolean;
  isPublic: boolean;
  fileId: string;
  fileUrl: string;
}

const COMMON = [NgClass, NgIf, NgFor, UpperCasePipe]

@Component({
  selector: "app-attachment",
  standalone: true,
  imports: [...COMMON, ValidationHandlerPipe, MatFormFieldModule, TranslateModule],
  templateUrl: "./attachment.component.html",
  styleUrl: "./attachment.component.scss",
})
export class AttachmentComponent extends ReactiveFormsBaseComponent {
  private attachmentService = inject(AttachmentService);

  fileToUpload!: any;
  imageExtensions: string[] = ["png", "jpg", "jpeg", "bmp", "tiff", "svg", "gif"];
  videosExtensions: string[] = ["mp4", "mov", "webm", "mkv", "flv", "avi", "wmv"];
  filesExtensions: string[] = [
    "pdf",
    "powerpoint",
    "word",
    "excel",
    "doc",
    "docx",
    "ppt",
    "pptx",
    "xls",
    "xlsx",
    "txt",
    "zip",
    "rar",
    "crt",
    "p7b",
    "html",
    "htm",
    "rtf",
    "msg",
  ];
  fileType!: string;
  acceptedTypes!: string;
  uploadedFiles: any[] = []; // insert all the files which the user choose.
  selectedFiles: File[] = []; // insert all the final files.
  isHidden = false;
  isSuccess = false;
  isFailed!: boolean;
  uploadedFile!: { type: string; maxSize: number };
  baseApiUrl!: string;
  downloadUrl!: string;
  oldFiles: any[] = [];

  /**
   * Inputs
   */
  @Input() isMultiple = false;
  @Input() allowedTypes!: { type: string; maxSize: number }[];
  @Input() maxFilesNumbers!: number;
  @Input() type: string = ""; // image | video | document | imageDocument | imageVideo | videoDocument'
  @Input() isPublic = false;
  @Input() label: string = "Attachment.Attachment";
  @Input() isEditMode = false;
  @Input() isCustom = false;
  @Input() isRequired = false;
  @Input() saveUrl = false;
  /**
   * Outputs
   */
  @Output() uploadEvent: EventEmitter<Attachment[]> = new EventEmitter();
  @Output() deleteEvent: EventEmitter<any> = new EventEmitter();

  @ViewChild("file") fileInput!: ElementRef;

  ngOnInit(): void {
    this.setAcceptedTypes();

    if (this.maxFilesNumbers > 0 && this.isEditMode) {
      this.selectedFiles = [];
      this.oldFiles = [];

      if (!this.control.value?.length) {
        this.isHidden = false;
      }

      if (this.control.value?.length) {
        this.control.value.forEach((element: { name: string; fileSize: any; url: string; documentType: string }) => {
          let file = {
            fileName: element.name,
            size: null,
            attachmentDisplaySize: this.convertSize({ size: element.fileSize }).attachmentDisplaySize,
            url: element.url,
            extension: element.documentType,
            isSuccess: true,
            isPublic: false,
            fileId: "1",
            fileUrl: "",
          };

          this.selectedFiles.push(file);
        });
      }
    }
  }

  /**
   * Set Accepted Types
   */
  setAcceptedTypes() {
    if (this.type === "image") {
      this.acceptedTypes = "image/*";
    } else if (this.type === "video") {
      this.acceptedTypes = "video/*";
    } else if (this.type === "imageVideo") {
      this.acceptedTypes = "image/*, video/*";
    } else if (this.type === "imageDocument") {
      this.acceptedTypes =
        "image/*, application/pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .txt, .rar, .zip, .crt, .p7b, .html, .htm, .rtf, .msg";
    } else if (this.type === "videoDocument") {
      this.acceptedTypes =
        "video/*, application/pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .txt, .rar, .zip, .crt, .p7b, .html, .htm, .rtf, .msg";
    } else if (this.type === "document") {
      this.acceptedTypes = "application/pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .txt, .rar, .zip, .crt, .p7b, .html, .htm, .rtf, .msg";
    } else {
      this.acceptedTypes = "*";
    }
  }

  /**
   * File Upload Event
   * @param event
   */
  onUploadFile(event: any) {
    if (event.target) {
      const files: any[] = event.target.files;
      this.uploadedFiles = files;
    } else {
      this.uploadedFiles = event;
    }

    if (this.selectedFiles.length + this.uploadedFiles.length <= this.maxFilesNumbers) {
      for (let file of this.uploadedFiles) {
        if (file.type.split("/").pop().toLowerCase() === "svg+xml") {
          this.fileType = "svg";
        } else if (!file.type) {
          // if the type equal 'null'
          if (file.name.split(".").pop().toLowerCase() === "doc" || file.name.split(".").pop().toLowerCase() === "docx") {
            this.fileType = "word";
          } else if (file.name.split(".").pop().toLowerCase() === "ppt" || file.name.split(".").pop().toLowerCase() === "pptx") {
            this.fileType = "powerpoint";
          } else if (file.name.split(".").pop().toLowerCase() === "xls" || file.name.split(".").pop().toLowerCase() === "xlsx") {
            this.fileType = "excel";
          } else if (file.name.split(".").pop().toLowerCase() === "rar") {
            this.fileType = "rar";
          } else if (file.name.split(".").pop().toLowerCase() === "msg") {
            this.fileType = "msg";
          }
        } else if (
          file.type.split("/").pop().toLowerCase() === "vnd.openxmlformats-officedocument.wordprocessingml.document" ||
          file.type.split("/").pop().toLowerCase() === "msword"
        ) {
          this.fileType = "word";
        } else if (
          file.type.split("/").pop().toLowerCase() === "vnd.openxmlformats-officedocument.presentationml.presentation" ||
          file.type.split("/").pop().toLowerCase() === "vnd.ms-powerpoint"
        ) {
          this.fileType = "powerpoint";
        } else if (
          file.type.split("/").pop().toLowerCase() === "vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          file.type.split("/").pop().toLowerCase() === "vnd.ms-excel"
        ) {
          this.fileType = "excel";
        } else if (file.type.split("/").pop().toLowerCase() === "plain") {
          this.fileType = "txt";
        } else if (file.type.split("/").pop().toLowerCase() === "x-zip-compressed" || file.type.split("/").pop().toLowerCase() === "gz") {
          this.fileType = "zip";
        } else if (file.type.split("/").pop().toLowerCase() === "x-x509-ca-cert") {
          this.fileType = "crt";
        } else if (file.type.split("/").pop().toLowerCase() === "x-pkcs7-certificates") {
          this.fileType = "p7b";
        } else if (file.type.split("/").pop().toLowerCase() === "jpeg") {
          this.fileType = "jpg";
        } else if (file.type.split("/").pop().toLowerCase() === "html") {
          this.fileType = "html";
        } else if (file.type.split("/").pop().toLowerCase() === "htm") {
          this.fileType = "htm";
        } else {
          this.fileType = file.type.split("/").pop().toLowerCase();
        }
        // console.log(file.type)
        this.fileHandling(file);
      }
    } else {
      this.setError({ maxFiles: { requiredLength: this.maxFilesNumbers } });
    }
  }

  /**
   * Handle File On Select
   * @param file
   */
  fileHandling(file: any) {
    const fileType = this.allowedTypes.find((allowedType) => allowedType.type === this.fileType);
    this.uploadedFile = fileType as { type: string; maxSize: number };

    if (fileType) {
      if (file.size <= fileType.maxSize * 1024 * 1024) {
        if (this.selectedFiles.find((selectedFile) => selectedFile.fileName === file.name)) {
          this.setError({ fileSelected: true });
        } else {
          let finalFile: any;
          let reader = new FileReader();
          reader.readAsDataURL(file);

          reader.onload = () => {
            finalFile = {
              fileName: file.name,
              size: file.size,
              url: reader.result,
              extension: this.fileType,
              isSuccess: false,
              content: "",
            };
          };

          reader.onloadend = () => {
            this.convertSize(finalFile);
            let content = reader.result?.toString();
            if (this.isCustom) {
              finalFile["content"] = content?.substr(content.indexOf(",") + 1);
            }
            this.selectedFiles.push(finalFile);

            if (this.uploadedFiles.length > 1) {
              this.control?.setErrors({ pending: true });
            }

            this.saveFile(this.uploadedFiles);
            if (this.selectedFiles.length === this.maxFilesNumbers) {
              this.isHidden = true;
            }
          };
        }
      } else {
        this.setError({ maxSize: { requiredLength: this.uploadedFile.maxSize } });
      }
    } else {
      this.setError({ allowedTypes: this.allowedTypes.map((each) => each.type) });
    }
  }

  /**
   * Convert File Size
   * @param finalFile
   */
  convertSize(finalFile: { size: any; attachmentDisplaySize?: any }) {
    const fileSize = +finalFile.size;

    if (!fileSize.toString().includes(".") && fileSize > 1) {
      // in case the size with bytes
      if (fileSize <= 1024 * 1024) {
        finalFile["attachmentDisplaySize"] = (fileSize / Math.pow(1024, 1)).toFixed() + " KB";
      } else if (fileSize <= 1024 * 1024 * 1024) {
        finalFile["attachmentDisplaySize"] = (fileSize / Math.pow(1024, 2)).toFixed(1) + " MB";
      }
    } else if (fileSize < 1) {
      // in case the size < 1 MB and the size returns from API - NOTE - if the size returns with MB
      if (fileSize * 1024 <= 1024 * 1024) {
        finalFile["attachmentDisplaySize"] = (fileSize * Math.pow(1024, 1)).toFixed() + " KB";
      }
    } else {
      // in case the size > 1 MB and the size returns from API - NOTE - if the size returns with MB
      finalFile["attachmentDisplaySize"] = fileSize.toFixed(1) + " MB";
    }

    return finalFile;
  }

  /**
   *
   * @param error `ValidationErrors | null`
   * @description reset file input value to the user can select the file again.
   */
  private setError(error: ValidationErrors | null) {
    this.control.setErrors(error);
    this.control.markAsDirty();
    this.fileInput.nativeElement.value = "";

    setTimeout(() => {
      this.control.setErrors(null);
    }, 3000);
  }

  /**
   * Remove File From Viewing List
   * Then Remove It From Api
   * @param fileIndex
   * @param file
   */
  removeFile(fileIndex: number, file: any) {
    const removedFile = this.oldFiles.find((x) => x.fileName == file.fileName);
    if (this.control.enabled) {
      this.selectedFiles.splice(fileIndex, 1);
      this.control.value.splice(fileIndex, 1);
    }

    this.selectedFiles.length ? "" : (this.isSuccess = false);
    this.isHidden = false;
    this.selectedFiles.length ? "" : this.setError({ required: true });
    this.deleteFileFromServer(removedFile);
  }

  /**
   * Save File To Server
   * @param files
   */
  saveFile(files: File[]) {
    this.callingAPI(files).subscribe(
      (res: Attachment[]) => {
        res.map((file) => {
          let selectedFile: any = this.selectedFiles.find((selectedFile) => selectedFile.fileName === file.name);
          let selectedIndex = this.selectedFiles.indexOf(selectedFile);

          let finalFile: any = {
            fileName: file.name,
            size: file.fileSize,
            url: this.downloadUrl + file.id,
            extension: file.documentType.toLowerCase(),
            fileId: file.id,
            fileUrl: this.downloadUrl,
            isSuccess: true,
            isPublic: this.isPublic,
          };

          if (this.isCustom) {
            finalFile["content"] = selectedFile["content"];
          }

          this.convertSize(finalFile);
          this.oldFiles.push(finalFile);
          this.selectedFiles[selectedIndex].isSuccess = true;
          // this.selectedFiles.splice(selectedIndex, 1, finalFile);
        });

        if (this.maxFilesNumbers === 1 && this.saveUrl) {
          this.control.setValue(this.oldFiles[0].fileId);
        } else {
          this.control.setValue(this.oldFiles);
        }

        this.control.markAsDirty();
        this.control.setErrors(null);

        this.uploadedFiles = [];
        this.fileInput.nativeElement.value = "";
        this.isFailed = false;
        this.isSuccess = true;
        this.uploadEvent.emit(res);
      },
      () => {
        this.isFailed = true;
      },
    );
  }

  /**
   * Call Server Api
   * @param files
   * @returns
   */
  callingAPI(files: any[]): Observable<any> {
    let formData = new FormData();

    for (let index = 0; index < files.length; index++) {
      formData.append("files", files[index], files[index].name);
    }

    return this.attachmentService.upload(formData, this.isPublic);
  }

  /**
   * Delete File From Server
   * @param file
   */
  deleteFileFromServer(file: any): void {
    this.attachmentService.delete(file.fileId).subscribe((res: any) => {
      this.deleteEvent.emit(file);
    });
  }
}
