<div class="collapse-wrapper font-14 w-100 h-100">
  <div class="w-100" [class.h-max]="sidebarItem.children?.length">
    <a
      class="font-12 w-100 d-flex align-items-center justify-content-between cursor-pointer each"
      [class.active]="RLA.isActive"
      #RLA="routerLinkActive"
      routerLinkActive
      [routerLink]="sidebarItem.children?.length ? null : [sidebarItem.routerLinkName]"
      (click)="collapse(sidebarItem.children)"
    >
      <div class="d-flex align-items-center w-max w-100" [class.gap-2]="isSidebarOpened$ | async">
        @if (sidebarItem.icon) {
          <em class="{{ sidebarItem.icon }} font-26 text-white" [title]="sidebarItem.label | translate">
            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
          </em>
        }

        <span class="text-white label w-max {{ (isSidebarOpened$ | async) ? 'd-block' : 'd-none' }}">
          {{ sidebarItem.label | translate }}
        </span>
      </div>

      @if (sidebarItem.children) {
        <em [class.animate]="showContent" class="db-icon-chevron-down text-white font-16"></em>
      }
    </a>

    @if (sidebarItem.children?.length) {
      <div class="w-100 nested-items" [class.showContent]="showContent">
        <mat-list class="d-flex flex-column p-0" role="list">
          @for (child of sidebarItem.children; track $index) {
            @if (child.permissions && child.permissions.includes(roleCode)) {
              <mat-list-item role="listitem">
                <a class="font-12 w-100 h-100 cursor-pointer" [routerLink]="[child.routerLinkName]">
                  <div class="d-flex align-items-center gap-2 each px-4 w-100" routerLinkActive="active">
                    @if (child.icon) {
                      <em class="{{ child.icon }} font-22 text-white" [title]="child.label | translate">
                        <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                      </em>
                    }

                    <span class="text-white label {{ (isSidebarOpened$ | async) ? 'd-block' : 'd-none' }}">
                      {{ child.label | translate }}
                    </span>
                  </div>
                </a>
              </mat-list-item>
            }
          }
        </mat-list>
      </div>
    }
  </div>
</div>
