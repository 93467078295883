@if (otp) {
  <div class="d-block mt-4 animate">
    <form class="d-block otp-form" [formGroup]="otpForm" (ngSubmit)="verifyOtp()">
      @if (!userPhoneNumber) {
        <app-input-text formControlName="phoneNumber" appearance="fill" contentType="number" label="FIELDS.PHONE">
          <em class="db-icon-phone font-24 opacity-50" matPrefix></em>
        </app-input-text>
      }

      <div class="form-group m-0 w-100 otp" dir="ltr">
        <input
          id="codeBox1"
          type="number"
          min="0"
          max="9"
          maxlength="1"
          placeholder=" "
          (keydown)="onKeyDownEvent($event)"
          (keyup)="onKeyUpEvent(1, $event)"
          (focus)="onFocusEvent(1)"
        />
        <input
          id="codeBox2"
          type="number"
          min="0"
          max="9"
          maxlength="1"
          placeholder=" "
          (keydown)="onKeyDownEvent($event)"
          (keyup)="onKeyUpEvent(2, $event)"
          (focus)="onFocusEvent(2)"
        />
        <input
          id="codeBox3"
          type="number"
          min="0"
          max="9"
          maxlength="1"
          placeholder=" "
          (keydown)="onKeyDownEvent($event)"
          (keyup)="onKeyUpEvent(3, $event)"
          (focus)="onFocusEvent(3)"
        />
        <input
          id="codeBox4"
          type="number"
          min="0"
          max="9"
          maxlength="1"
          placeholder=" "
          (keydown)="onKeyDownEvent($event)"
          (keyup)="onKeyUpEvent(4, $event)"
          (focus)="onFocusEvent(4)"
        />
      </div>
    </form>

    <div class="resend-wrapper d-flex justify-content-between align-items-center mt-3 w-100">
      <app-button
        class="d-block"
        btnClass="w-100 font-14 without-shadow secondary h-40"
        btnLabel="ACTIONS.RESEND"
        color="primary"
        [disabled]="!allowResend"
        (onClick)="allowResend ? resendOTP() : ''"
      />

      <span class="text-secondary">{{ counter }}</span>
    </div>

    <div class="form-actions mt-3">
      <app-button
        class="w-100 d-block"
        btnClass="w-100 font-18 primary h-40 px-4"
        color="accent"
        [type]="'submit'"
        [btnLabel]="'ACTIONS.CONFIRM'"
        [disabled]="otpForm.invalid"
        (onClick)="verifyOtp()"
      />

      <a [routerLink]="['/auth/login']">
        <app-button
          class="w-100 mt-3 d-block"
          btnClass="w-100 font-18 without-shadow secondary h-40"
          btnLabel="ACTIONS.CANCEL"
          color="primary"
        />
      </a>
    </div>
  </div>
}
