import { Component } from '@angular/core';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  template: ``,
})
export class DialogComponent {
  constructor(private dialog: MatDialog, private router: Router, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.data.pipe(takeUntilDestroyed()).subscribe(data => this.openDialog(data));
  }

  openDialog(data: Data): void {
    const dialogRef = this.dialog.open(data['component'], {
      autoFocus: false,
      panelClass: ['medium', 'p-0'],
      data: { activatedRoute: this.activatedRoute.parent },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntilDestroyed())
      .subscribe((_) => {
        this.router.navigate([data['redirectTo'] ? data['redirectTo'] : '..'], {
          relativeTo: this.activatedRoute,
        });
      });
  }
}

// Usage Way...
// {
//     path: 'path-name',
//     data: { component: PathNameComponent },
//     component: DialogComponent
//   }
