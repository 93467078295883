import { Injectable } from "@angular/core";
import { HttpService } from "src/app/core";
import { PaymentPayload } from "../../interfaces";

@Injectable({
  providedIn: "root",
})
export class PaymentService extends HttpService {
  protected override get baseUrl(): string {
    return "payment/";
  }

  addPayment(body: Partial<PaymentPayload>) {
    return this.post<string>({ APIName: "pay", body });
  }
}
