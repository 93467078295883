import { MatMenuModule } from "@angular/material/menu";
import { NgTemplateOutlet } from "@angular/common";
import { RouterLink, RouterLinkActive } from "@angular/router";
import { Component, EventEmitter, Input, Output, TemplateRef, ViewEncapsulation } from "@angular/core";

import { MenuItem } from "src/app/core/interfaces";
import { ButtonComponent } from "../button/button.component";

@Component({
  selector: "app-menu",
  standalone: true,
  imports: [NgTemplateOutlet, RouterLink, RouterLinkActive, MatMenuModule, ButtonComponent],
  templateUrl: "./menu.component.html",
  styleUrl: "./menu.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class MenuComponent {
  @Input() menuTemplateRef!: TemplateRef<unknown>;
  @Input({ required: true }) menuItemTemplateRef!: TemplateRef<unknown>;
  @Input({ required: true }) menuItems!: MenuItem[];
  @Input() btnLabel!: string;
  @Input() btnClass!: string;
  @Input() menuClass!: string;
  @Input() backdropClass: string = "ahmed";
  @Input() color: "primary" | "accent" | "warn" | null = null;

  @Output() onMenuClosed = new EventEmitter();
  @Output() onMenuOpened = new EventEmitter();
}
