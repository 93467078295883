import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";

import { environment } from "src/environments/environment";

export const authGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);

  if (localStorage.getItem(environment.tokenPropertyName)) {
    if (state.url.includes("/auth")) {
      router.navigate(["/services"]);
      return false;
    }
  } else {
    if (!state.url.includes("/auth")) {
      router.navigate(["/auth"]);
      return false;
    }
  }

  return true;
};
