<div class="mat-mdc-tabs-wrapper" (isEnglish)="isEnglish = $event" [dir]="isEnglish ? 'ltr' : 'rtl'">
  <mat-tab-group
    animationDuration="0"
    (selectedIndexChange)="selectedIndexChange.emit($event)"
    [selectedIndex]="selectedIndex"
    [preserveContent]="!removeInActiveTabContent"
  >
    @for (tab of tabs; track tab.key) {
      <mat-tab>
        <ng-template mat-tab-label>
          <a class="d-flex align-items-center h-100" [routerLink]="tab.routerLink ? [tab.routerLink] : null">
            <span class="font-14">
              {{ tab.title | translate }}

              @if (tab.tabItemsCount$) {
                <ng-container> ({{ (tab.tabItemsCount$ | async) || "0" }}) </ng-container>
              }
            </span>
          </a>
        </ng-template>

        <ng-template matTabContent>
          <div class="mt-3">
            @if (tab.component) {
              <ng-container [ngComponentOutlet]="tab.component" [ngComponentOutletInputs]="tab?.componentInputs"></ng-container>
            }

            @if (tab.template) {
              <ng-container [ngTemplateOutlet]="tab.template" [ngTemplateOutletContext]="{ $implicit: tab }"></ng-container>
            }
          </div>
        </ng-template>
      </mat-tab>
    }
  </mat-tab-group>
</div>

<!-- Usage
  <app-tabs [tabs]="tabs" (selectedIndexChange)="onSelectedTab($event)" />
-->
