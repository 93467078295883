<div class="modal-container d-flex flex-column">
  <div class="modal-header bg-header justify-content-between align-items-center w-100" mat-dialog-header>
    <h1 class="m-0 p-0 font-24 text-dark w-100" mat-dialog-title>
      {{ "BE_VALIDATION.DELETE_CONFIRMATION" | translate }}
    </h1>
  </div>

  <div class="px-4 py-3 m-0" mat-dialog-content>
    <p class="m-0 font-16 text-danger">{{ "ALERT.DELETE_MESSAGE" | translate }}</p>
  </div>

  <div class="modal-footer w-100 d-flex justify-content-end gap-3" mat-dialog-actions>
    <app-button btnLabel="ACTIONS.CONFIRM" btnClass="font-14 px-3" color="primary" (click)="dialogRef.close(true)" />
    <app-button btnLabel="ACTIONS.CANCEL" btnClass="font-14 px-3 btn-outline" mat-dialog-close />
  </div>
</div>
