import { Routes } from "@angular/router";
import { myTicketsRoutes } from "src/app/features/my-tickets";

const myReservationsBaseRoute = myTicketsRoutes[0].children![0];
const myReservationsDetails = myTicketsRoutes[1];

export const reservationsRoutes: Routes = [
  { path: "", redirectTo: "services", pathMatch: "full" },
  {
    path: "services",
    children: [myReservationsBaseRoute],
  },
  {
    path: "view",
    children: [myReservationsDetails],
  },
  // {
  //   path: "confirm",
  //   children: [myReservationsDetails],
  // },
];
