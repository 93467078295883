import { Routes } from "@angular/router";

import { packagesManagementRoutes, packagesListRoutes } from "./nested-features/packages";
import { guidedToursManagementRoutes, guidedToursRoutes } from "./nested-features/guided-tours-programs";

import { Permissions } from "src/assets/config/config";

export const servicesManagementRoutes: Routes = [
  {
    path: "",
    title: "SERVICES.SERVICES_MANAGEMENT",
    data: {
      tabs: [
        {
          key: "Packages",
          title: "SERVICES.Packages",
          routerLink: "packages",
        },
        {
          key: "GuidedTours",
          title: "SERVICES.GuidedTours",
          routerLink: "guided-tours",
        },
      ],
    },
    loadComponent: () =>
      import("src/app/shared/base-components/base-tabs-management/base-tabs-management.component").then(
        (c) => c.BaseTabsManagementComponent,
      ),
    children: [
      { path: "", redirectTo: "packages", pathMatch: "full" },
      {
        path: "packages",
        data: { permissions: Permissions.Packages },
        children: packagesListRoutes,
      },
      {
        path: "guided-tours",
        data: { permissions: Permissions.GuidedTours },
        children: guidedToursRoutes,
      },
    ],
  },
  {
    path: "packages",
    data: { permissions: Permissions.Packages },
    children: packagesManagementRoutes,
  },
  {
    path: "guided-tours",
    data: { permissions: Permissions.GuidedTours },
    children: guidedToursManagementRoutes,
  },
];
