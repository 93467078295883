import { inject } from "@angular/core";
import { CanActivateChildFn, Router } from "@angular/router";

import { UserRole } from "../../enums";
import { HttpService } from "../../services";

export const roleGuard: CanActivateChildFn = (childRoute, state) => {
  const router = inject(Router);
  const http = inject(HttpService);

  const roleCode = http.convertTokenJWT()?.RoleCode;
  if (!roleCode) {
    localStorage.clear();
    router.navigate(["/"]);
    return false;
  }

  if (!childRoute.data["permissions"]) {
    throw new Error("There's no permissions");
  }

  if (childRoute.data["permissions"].includes(roleCode)) return true;

  if (roleCode === UserRole.COMPANY) {
    router.navigate(["/services"]);
    return true;
  } else if (roleCode.includes("EVENT")) {
    router.navigate(["/admin/events"]);
    return true;
  } else if (roleCode.includes(UserRole.COUNTER)) {
    router.navigate(["/admin/calendar"]);
    return true;
  } else if ([UserRole.STATISTICUSER, UserRole.FINANCIALUSER].includes(roleCode)) {
    router.navigate(["/admin/reports"]);
    return true;
  } else if (roleCode.includes(UserRole.KIOSK)) {
    router.navigate(["/kiosk"]);
    return true;
  } else if (roleCode.includes(UserRole.HALLAPPROVER)) {
    router.navigate(["/admin/reservations/hall"]);
    return true;
  } else if (roleCode === UserRole.CUSTOMER) {
    router.navigate(["/home"]);
    return true;
  } else {
    if (childRoute.data["permissions"].includes(UserRole.CUSTOMER)) {
      router.navigate(["/admin"]);
      return true;
    }
  }

  router.navigateByUrl("/error/403");
  return false;
};
