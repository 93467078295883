import { Pipe, PipeTransform, inject } from "@angular/core";
import { Checkbox, Dropdown, Lookup, TranslationService } from "src/app/core";

@Pipe({
  name: "localizedName",
  standalone: true,
  pure: false,
})
export class LocalizedNamePipe implements PipeTransform {
  private translationService = inject(TranslationService);

  transform(value: Lookup | Checkbox | Dropdown, ...args: unknown[]): unknown {
    const isEnglish = this.translationService.isEnglish;
    return isEnglish ? value.nameEn : value.nameAr;
  }
}
