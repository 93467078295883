<form class="d-block mt-4 animate mb-3" [formGroup]="registerForm" (ngSubmit)="register()">
  <app-input-text formControlName="name" appearance="fill" label="FIELDS.FULL_NAME" matErrorClass="text-secondary">
    <em class="db-icon-user font-24 opacity-50" matPrefix></em>
  </app-input-text>

  <app-input-text
    formControlName="email"
    contentType="email"
    appearance="fill"
    label="FIELDS.EMAIL"
    placeholder="xyz@domain.com"
    matErrorClass="text-secondary"
  >
    <em class="db-icon-email font-24 opacity-50" matPrefix></em>
  </app-input-text>

  <app-input-text formControlName="phone" contentType="number" appearance="fill" label="FIELDS.PHONE" matErrorClass="text-secondary">
    <em class="db-icon-phone font-24 opacity-50" matPrefix></em>
  </app-input-text>

  <app-datepicker
    formControlName="dateOfBirth"
    appearance="fill"
    label="FIELDS.BIRTH_DATE"
    matErrorClass="text-secondary"
    [maxDate]="today"
  />

  <app-input-text
    formControlName="password"
    [contentType]="showPassword ? 'text' : 'password'"
    appearance="fill"
    matErrorClass="text-secondary"
    label="FIELDS.PASSWORD"
  >
    <em class="db-icon-lock font-24 opacity-50" matPrefix></em>
    <em class="db-icon-{{ showPassword ? 'eye-off' : 'eye' }} font-24 opacity-50" (click)="showPassword = !showPassword" matSuffix></em>
  </app-input-text>

  <app-input-text
    formControlName="confirmPassword"
    [contentType]="showPassword ? 'text' : 'password'"
    appearance="fill"
    matErrorClass="text-secondary"
    label="FIELDS.CONFIRMPASSWORD"
  >
    <em class="db-icon-lock font-24 opacity-50" matPrefix></em>
    <em class="db-icon-{{ showPassword ? 'eye-off' : 'eye' }} font-24 opacity-50" (click)="showPassword = !showPassword" matSuffix></em>
  </app-input-text>

  <app-button
    class="w-100"
    btnClass="w-100 font-18 primary h-40 px-4"
    btnLabel="ACTIONS.REGISTER"
    color="accent"
    [type]="'submit'"
    [disabled]="registerForm.invalid"
  />
</form>
