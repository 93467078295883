<div class="mat-mdc-form-field-wrapper form-group d-flex flex-column m-0">
  @if (options.length) {
    <div class="d-flex flex-column">
      @if (label) {
        <label [for]="formControlName" class="text-primary font-16 mb-2">{{ label | translate }}</label>
      }

      <div class="px-2 d-flex flex-column flex-md-row gap-1 justify-content-around">
        @for (option of options; track $index) {
          <mat-checkbox [id]="formControlName + $index" [checked]="option.checked" [color]="color" (change)="change($event, option)">
            {{ option | localizedName }}
          </mat-checkbox>
        }
      </div>
    </div>
  } @else {
    <mat-checkbox [checked]="checked || value" (change)="change($event)" [color]="color">
      <span class="text-primary fw-bold">
        {{ label | translate }}
      </span>
    </mat-checkbox>
  }
</div>
