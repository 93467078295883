<div class="d-block mt-4 animate">
  @if (forgetPasswordTabs.length) {
    <app-tabs [tabs]="forgetPasswordTabs" (selectedIndexChange)="getActiveTab($event)" />
  }

  <div class="form-actions">
    <app-button
      class="w-100 mt-3 d-block"
      btnClass="w-100 font-18 primary h-40 px-4"
      color="accent"
      [type]="'submit'"
      [btnLabel]="'ACTIONS.CONTINUE'"
      [disabled]="forgetPasswordForm.invalid"
      (onClick)="forget()"
    />

    <a [routerLink]="['/auth/login']">
      <app-button
        class="w-100 mt-3 d-block"
        btnClass="w-100 font-18 without-shadow secondary h-40"
        btnLabel="ACTIONS.CANCEL"
        color="primary"
      />
    </a>
  </div>
</div>

<ng-template #byEmailTab>
  <form class="d-block" [formGroup]="forgetPasswordForm" (ngSubmit)="forget()">
    <app-input-text formControlName="email" placeholder="xyz@domain.com" appearance="fill" contentType="email" label="FIELDS.EMAIL">
      <em class="db-icon-phone font-24 opacity-50" matPrefix></em>
    </app-input-text>
  </form>
</ng-template>

<ng-template #byPhoneTab>
  <form class="d-block" [formGroup]="forgetPasswordForm" (ngSubmit)="forget()">
    <app-input-text formControlName="phone" appearance="fill" contentType="number" label="FIELDS.PHONE">
      <em class="db-icon-phone font-24 opacity-50" matPrefix></em>
    </app-input-text>
  </form>
</ng-template>
