import { HttpInterceptorFn } from "@angular/common/http";
import { inject } from "@angular/core";
import { finalize } from "rxjs";

import { LoadingService } from "../../services";

export const loadingInterceptor: HttpInterceptorFn = (req, next) => {
  const loadingService = inject(LoadingService);

  setTimeout(() => loadingService.setLoading(true, req.url));

  return next(req).pipe(finalize(() => loadingService.setLoading(false, req.url)));
};
