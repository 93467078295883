import { inject, Injectable } from "@angular/core";
import { TitleStrategy, RouterStateSnapshot } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class TitleService extends TitleStrategy {
  private tranlateService = inject(TranslateService);

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);

    if (title) document.title = title;
  }

  override buildTitle(routerState: RouterStateSnapshot): string | undefined {
    const baseTitle = this.tranlateService.instant("app_title");
    let currentRoute = routerState.root;
    let title = "";

    while (currentRoute) {
      if (!currentRoute?.firstChild) {
        title = currentRoute.title ?? "";
        break;
      }

      currentRoute = currentRoute.firstChild;
    }

    if (!title) return baseTitle;

    return `${baseTitle} - ${this.tranlateService.instant(title)}`;
  }
}
