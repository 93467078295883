import { Injectable } from "@angular/core";

import { GetPagedBody, GetPagedResponse, HttpService, HttpServiceBaseCRUD, Lookup } from "src/app/core";

@Injectable({
  providedIn: "root",
})
export class MuseumBuildingService extends HttpService implements HttpServiceBaseCRUD {
  protected override get baseUrl(): string {
    return "museumbuildings/";
  }

  add(body: any) {
    return this.post<Lookup>({ APIName: "add", body, showAlert: true });
  }

  update(body: any) {
    return this.put<Lookup>({ APIName: "update", body, showAlert: true });
  }

  remove(id: string) {
    return this.delete<boolean>({ APIName: `deletesoft/${id}`, showAlert: true });
  }

  getById(id: string) {
    return this.get<Lookup>({ APIName: `get/${id}` });
  }

  getAll() {
    return this.get<Lookup[]>({ APIName: "getall" });
  }

  getPaged(body: GetPagedBody<Record<string, string | number | boolean>>) {
    return this.post<GetPagedResponse<Lookup[]>>({ APIName: "getpaged", body });
  }
}
