import { Directive, EventEmitter, Output, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

import { Languages, TranslationService } from "src/app/core";

@Directive({
  selector: "[isEnglish]",
  standalone: true,
})
export class IsEnglishDirective {
  private translation = inject(TranslationService);

  @Output() isEnglish = new EventEmitter<boolean>(true);

  constructor() {
    this.getCurrentLanguage();
  }

  private getCurrentLanguage() {
    this.translation.currentLanguage$.pipe(takeUntilDestroyed()).subscribe((land) => this.isEnglish.emit(land === Languages.EN));
    setTimeout(() => this.isEnglish.emit(this.translation.isEnglish));
  }
}
