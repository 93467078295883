import { TranslateModule } from "@ngx-translate/core";
import { Component, inject } from "@angular/core";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";

import { ButtonComponent } from "../button/button.component";

@Component({
  selector: "app-delete-modal",
  standalone: true,
  imports: [MatDialogModule, TranslateModule, ButtonComponent],
  templateUrl: "./delete-modal.component.html",
  styleUrl: "./delete-modal.component.scss",
})
export class DeleteModalComponent {
  dialogRef = inject(MatDialogRef<DeleteModalComponent>);
}
