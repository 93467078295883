<div class="mat-mdc-form-field-wrapper form-group d-flex flex-column m-0" [class.mb-3]="control.errors && control.touched">
  <mat-form-field class="text-start transparent" [appearance]="appearance">
    <mat-label [attr.for]="formControlName" class="text-middle font-14">
      {{ label | translate }}
    </mat-label>

    @if (inputType === "textbox") {
      <input
        matInput
        [type]="contentType"
        [readonly]="readonly"
        [placeholder]="placeholder | translate"
        [formControl]="control"
        (keydown)="control!.markAsTouched()"
        (keypress)="onKeyPress($event)"
        (keyup.enter)="inputValue.emit(control!.value)"
      />
    }

    @if (inputType === "textarea") {
      <textarea
        matInput
        rows="4"
        [readonly]="readonly"
        [placeholder]="placeholder | translate"
        [formControl]="control"
        (keydown)="control!.markAsTouched()"
      >
      </textarea>
    }

    <span class="matSuffix d-flex align-items-center justify-content-center ps-2 h-100" matSuffix>
      <ng-content select="[matSuffix]"></ng-content>
    </span>

    <span class="matPrefix d-flex align-items-center justify-content-center" matPrefix>
      <ng-content select="[matPrefix]"></ng-content>
    </span>

    <mat-hint class="mat-hint mt-1">
      <ng-content select="[matHint]"></ng-content>
    </mat-hint>

    @if (control.errors) {
      <mat-error [class]="matErrorClass">
        <span class="font-12">
          {{ control.errors | validationHandler }}
        </span>
      </mat-error>
    }
  </mat-form-field>
</div>

<!-- Usage
  <app-input-text
    label="label"
    formControlName="xyz"
    (inputValue)="getInputValue($event)"
  >
  </app-input-text>
-->
