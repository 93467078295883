import { toSignal } from "@angular/core/rxjs-interop";
import { SwiperContainer } from "swiper/element";
import { Swiper, SwiperOptions } from "swiper/types";
import { Directive, effect, ElementRef, inject, Injector, input, runInInjectionContext } from "@angular/core";

import { TranslationService } from "src/app/core";

@Directive({
  selector: "[appSwiper]",
  standalone: true,
})
export class SwiperDirective {
  private el = inject(ElementRef<SwiperContainer>);
  private injector = inject(Injector);
  private translation = inject(TranslationService);

  config = input.required<SwiperOptions>({});

  private swiper!: Swiper;
  private isEnglish = toSignal(this.translation.currentLanguage$);

  ngAfterViewInit() {
    Object.assign(this.el.nativeElement, this.config());

    this.el.nativeElement.initialize();
    this.swiper = this.el.nativeElement.swiper as Swiper;

    runInInjectionContext(this.injector, () =>
      effect(() => this.swiper.changeLanguageDirection(this.isEnglish() === "en" ? "ltr" : "rtl")),
    );
  }
}
