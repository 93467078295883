import { Routes } from "@angular/router";

import { bookRoutes } from "../book-service";

export const servicesRoutes: Routes = [
  {
    path: "",
    loadComponent: () => import("./pages/services/services.component").then((c) => c.ServicesComponent),
  },
  {
    path: "book",
    children: [{ path: "", redirectTo: "/services", pathMatch: "full" }, ...bookRoutes],
  },
  // {
  //   path: "events",
  //   children: [
  //     {
  //       path: ":eventCode",
  //       children: [
  //         {
  //           path: "", // list of events.
  //         },
  //         {
  //           path: ":eventId", // each event details.
  //         }
  //       ]
  //     }
  //   ]
  // }
];
