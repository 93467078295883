import { TranslateModule } from "@ngx-translate/core";
import { Component, inject } from "@angular/core";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";

import { AuthService, UserRole } from "src/app/core";
import { ButtonComponent, InputTextComponent } from "src/app/shared";

@Component({
  selector: "app-login",
  standalone: true,
  imports: [RouterLink, TranslateModule, ReactiveFormsModule, InputTextComponent, ButtonComponent],
  templateUrl: "./login.component.html",
  styleUrl: "./login.component.scss",
})
export class LoginComponent {
  private fb = inject(FormBuilder);
  private authService = inject(AuthService);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);

  loginForm!: FormGroup;

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.initForm();
  }

  initForm() {
    this.loginForm = this.fb.group({
      username: ["", [Validators.required]],
      password: ["", [Validators.required, Validators.minLength(6)]],
    });
  }

  login() {
    this.authService.login(this.loginForm.value).subscribe({
      next: () => {
        this.handleNavigation(this.activatedRoute.snapshot.queryParamMap.get("redirect") || "");
      },
      error: (error) => {
        if (error.message === "AccountNotVerified") {
          this.router.navigateByUrl("/auth/verify");
          return;
        }
      },
    });
  }

  handleNavigation(redirect: string): void {
    const roleCode = this.authService.convertTokenJWT()?.RoleCode;
    if (!roleCode) return;

    if (redirect) {
      this.router.navigateByUrl(redirect);
    } else if (roleCode.includes(UserRole.KIOSK)) {
      this.router.navigateByUrl("/kiosk/select-language");
    } else {
      this.router.navigateByUrl("/");
    }
  }
}
