<label class="text-middle mt-1" for="attachment">{{ label }} <span *ngIf="control.hasError('required')">*</span></label>

<div class="attachment" *ngIf="form">
  <div class="attachment__header mb-4">
    <div class="d-flex align-items-center mb-2">
      <span class="failed" *ngIf="isFailed">{{ "Attachment.Failed" | translate }}</span>
    </div>

    <div class="upload d-flex flex-column justify-content-center align-self-center" [ngClass]="{ disabled: isHidden }">
      <div class="text-center">
        <input
          type="button"
          class="fw-bold px-0"
          value="{{ 'Attachment.Browse' | translate }}"
          [disabled]="isHidden || maxFilesNumbers === selectedFiles.length"
          (click)="control.enabled ? file.click() : ''"
        />
      </div>

      <div class="text-center">
        <div class="SmallSubTitle">
          {{ "Attachment.SubMsg" | translate }}
          <span *ngFor="let item of allowedTypes; let itemIndex = index">
            <span *ngIf="itemIndex < allowedTypes.length - 2">{{ item.type | uppercase }}, </span>
            <span *ngIf="itemIndex === allowedTypes.length - 2">{{ item.type | uppercase }}</span>
            <span *ngIf="itemIndex === allowedTypes.length - 1"> {{ "AND" | translate }} {{ item.type | uppercase }}</span>
          </span>
        </div>
        <div class="SmallSubTitle">{{ "Attachment.MaxFilesMsg" | translate }}{{ maxFilesNumbers }}</div>
      </div>
    </div>

    <input
      #file
      id="attachment"
      class="form-control textField d-none"
      type="file"
      [multiple]="isMultiple"
      [accept]="acceptedTypes"
      (change)="onUploadFile($event)"
    />
  </div>

  <div class="attachment__files-wrapper">
    <div [ngClass]="{ 'mb-3': fileIndex < selectedFiles.length - 1 }" *ngFor="let file of selectedFiles; let fileIndex = index">
      <div class="attachment__file d-flex align-items-center justify-content-between" [ngClass]="{ 'bg-error': isFailed }">
        <div class="file-details d-flex align-items-center">
          <em class="db-icon-download text-gradient-success font-26" *ngIf="filesExtensions.includes(file.extension)"></em>
          <img [src]="file.url" *ngIf="imageExtensions.includes(file.extension)" />

          <span class="text-truncate mx-2 file-name" for="file">{{ file.fileName }}</span>

          <span *ngIf="!isFailed && file.attachmentDisplaySize" class="CardSubTitle mx-3 file-size">
            ( {{ file.attachmentDisplaySize }} )
          </span>
        </div>

        <span class="d-flex align-items-center justify-content-center">
          <em
            class="db-icon-trash text-gradient-danger font-26 icons"
            *ngIf="!isFailed && file.isSuccess"
            (click)="file.isSuccess ? removeFile(fileIndex, file) : ''"
          ></em>

          <em class="db-icon-loader spinner d-block font-24" *ngIf="!file.isSuccess"></em>

          <em class="db-icon-upload text-primary font-26 icons" *ngIf="isFailed" (click)="saveFile(uploadedFiles)"></em>
        </span>
      </div>
    </div>

    <mat-error *ngIf="control.errors">
      <span class="font-10 text-danger">
        {{ control.errors | validationHandler }}
      </span>
    </mat-error>
  </div>
</div>
