import { Injectable } from "@angular/core";

import { HttpService, Lookup } from "src/app/core";

@Injectable({
  providedIn: "root",
})
export class NationalitiesService extends HttpService {
  protected override get baseUrl(): string {
    return "nationalities/";
  }

  add(body: Lookup & { visitorCategoryId: number }) {
    return this.post<Lookup>({ APIName: "add", body, showAlert: true });
  }

  update(body: Lookup & { visitorCategoryId: number }) {
    return this.put<Lookup>({ APIName: "update", body, showAlert: true });
  }

  remove(id: string) {
    return this.delete<boolean>({ APIName: `deletesoft/${id}`, showAlert: true });
  }

  getById(id: string) {
    return this.get<Lookup>({ APIName: `get/${id}` });
  }

  getAll() {
    return this.get<Lookup[]>({ APIName: "getall" });
  }

  getByCategoryCode(categoryCodes: string[]) {
    return this.post<Lookup[]>({ APIName: "getcategorycode", body: categoryCodes });
  }
}
