import { Routes } from "@angular/router";

export const packagesListRoutes: Routes = [
  {
    path: "",
    title: "SERVICES.Packages",
    loadComponent: () => import("./pages/packages-list/packages-list.component").then((m) => m.PackagesListComponent),
  },
];

export const packagesManagementRoutes: Routes = [
  {
    path: "add",
    title: "PACKAGES.ADD",
    loadComponent: () => import("./pages/add-edit-packages/add-edit-packages.component").then((m) => m.AddEditPackagesComponent),
  },
  {
    path: "edit/:id",
    title: "PACKAGES.EDIT",
    loadComponent: () => import("./pages/add-edit-packages/add-edit-packages.component").then((m) => m.AddEditPackagesComponent),
  },
];
