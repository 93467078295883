import { RouterOutlet } from "@angular/router";
import { Component, inject } from "@angular/core";

import { UserRole } from "../../enums";
import { NavbarComponent } from "../navbar/navbar.component";
import { SidebarComponent } from "../sidebar/sidebar.component";

import { UsersService } from "src/app/features";

@Component({
  selector: "app-layout",
  standalone: true,
  imports: [RouterOutlet, NavbarComponent, SidebarComponent],
  templateUrl: "./layout.component.html",
  styleUrl: "./layout.component.scss",
})
export class LayoutComponent {
  user = inject(UsersService).convertTokenJWT();

  get isGuest() {
    return this.user?.RoleCode === UserRole.GUEST;
  }
}
