import { HttpErrorResponse, HttpEventType, HttpInterceptorFn, HttpResponse } from "@angular/common/http";
import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { catchError, tap, throwError } from "rxjs";

import { environment } from "../../../../environments/environment";
import { API_Response } from "../../interfaces";

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  const router = inject(Router);
  const toastr = inject(ToastrService);
  const translation = inject(TranslateService);

  return next(req).pipe(
    tap((response) => {
      if (response.type === HttpEventType.Response) {
        const body = (response as HttpResponse<API_Response<unknown>>).body;
        if (body?.status && (body.status.toString().startsWith("5") || body.status.toString().startsWith("4"))) {
          if (body.message) toastr.error(translation.instant(`BE_VALIDATION.${body.message}`));
          else toastr.error("!NOT HANDLED ERROR!");
        }
      }
    }),
    catchError((error: HttpErrorResponse) => {
      if (error && error.status === 401) {
        // 401 errors are most likely going to be because we have an expired token that we need to refresh.
        localStorage.removeItem(environment.tokenPropertyName);
        localStorage.removeItem("userData");
        router.navigateByUrl("/auth");
      }

      if (!error.error || !error.error.data) {
        toastr.error("!INTERNAL SERVER ERROR!");
        return throwError(() => error);
      }

      // 400, 500
      if (error.status.toString().startsWith("5") || error.status.toString().startsWith("4")) {
        if (error.error) toastr.error(translation.instant(`BE_VALIDATION.${error.error?.message}`));
        else toastr.error("!NOT HANDLED ERROR!");
      }

      return throwError(() => error);
    }),
  );
};
