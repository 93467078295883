import { AsyncPipe } from "@angular/common";
import { MatListModule } from "@angular/material/list";
import { Component, ViewEncapsulation, inject } from "@angular/core";

import { UserRole } from "../../enums";
import { SidebarItem } from "../../interfaces";
import { SidebarListItemComponent } from "../sidebar-list-item/sidebar-list-item.component";
import { HttpService, SidebarToggleService } from "../../services";

import { Permissions } from "src/assets/config/config";

@Component({
  selector: "app-sidebar",
  standalone: true,
  imports: [AsyncPipe, MatListModule, SidebarListItemComponent],
  templateUrl: "./sidebar.component.html",
  styleUrl: "./sidebar.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent {
  roleCode = inject(HttpService).convertTokenJWT()?.RoleCode as UserRole;
  isSidebarOpened$ = inject(SidebarToggleService).isSidebarOpened$;
  sidebarItems: SidebarItem[] = [
    {
      label: "SERVICES.TITLE",
      icon: "db-icon-service",
      routerLinkName: "/services",
      permissions: Permissions.Services,
    },
    {
      label: "TICKETS.MY_RESERVATIONS",
      icon: "db-icon-ticket",
      routerLinkName: "/my-tickets",
      permissions: Permissions.MyTickets,
    },
    {
      label: "ADMIN_MANAGEMENT",
      icon: "db-icon-cog",
      routerLinkName: "/admin",
      permissions: Permissions.Admin,
      children: [
        // {
        //   label: "Membership.Title",
        //   icon: "db-icon-membership",
        //   routerLinkName: "/admin/memberships",
        //   permissions: Permissions.Memberships,
        // },
        // {
        //   label: "MembershipType.Title",
        //   icon: "db-icon-collection",
        //   routerLinkName: "/admin/membership-type",
        //   permissions: Permissions.MembershipType,
        // },
        // {
        //   label: "MembershipGroup.Title",
        //   icon: "db-icon-group",
        //   routerLinkName: "/admin/membership-group",
        //   permissions: Permissions.MembershipGroup,
        // },
        // {
        //   label: "EVENTS.TITLE",
        //   icon: "db-icon-calendar-event",
        //   routerLinkName: "/admin/events",
        //   permissions: Permissions.Events,
        // },
        // {
        //   label: "Calendar",
        //   icon: "db-icon-calendar",
        //   routerLinkName: "/admin/calendar",
        //   permissions: Permissions.Calendar,
        // },
        // {
        //   label: "Halls.Title",
        //   icon: "db-icon-buildings",
        //   routerLinkName: "/admin/halls",
        //   permissions: Permissions.Halls,
        // },
        // {
        //   label: "Product.Title",
        //   icon: "db-icon-map",
        //   routerLinkName: "/admin/product",
        //   permissions: Permissions.Product,
        // },
        // {
        //   label: "Nationality.Title",
        //   icon: "db-icon-passport",
        //   routerLinkName: "/admin/nationalities",
        //   permissions: Permissions.Nationalities,
        // },
        // {
        //   label: "Reports.Title",
        //   icon: "db-icon-sheet",
        //   routerLinkName: "/admin/reports",
        //   permissions: Permissions.Reports,
        // },
        // {
        //   label: "Audit.Title",
        //   icon: "db-icon-monitor",
        //   routerLinkName: "/admin/audit",
        //   permissions: Permissions.Audit,
        // },
        // {
        //   label: "SERVICES.SPECIAL",
        //   icon: "db-icon-ticket",
        //   routerLinkName: "/admin/special-tickets",
        //   permissions: Permissions.SpecialTickets,
        // },
        {
          label: "SERVICES.TITLE",
          icon: "db-icon-service",
          routerLinkName: "/admin/booking-services",
          permissions: Permissions.BookingServices,
        },
        {
          label: "USERS.USERS_MANAGEMENT",
          icon: "db-icon-user",
          routerLinkName: "/admin/users-management",
          permissions: Permissions.UsersManagement,
        },
        {
          label: "SERVICES.SERVICES_MANAGEMENT",
          icon: "db-icon-services-management",
          routerLinkName: "/admin/services-management",
          permissions: Permissions.ServicesManagement,
        },
        {
          label: "TICKETS.RESERVATIONS",
          icon: "db-icon-reservation",
          routerLinkName: "/admin/reservations",
          permissions: Permissions.Reservations,
        },
        // {
        //   label: "SERVICES.HALL_RESERVATIONS",
        //   icon: "db-icon-reservation",
        //   routerLinkName: "/admin/reservations/hall",
        //   permissions: [...Permissions.Reservations, ...Permissions.HallsReservations],
        // },
        // {
        //   label: "SERVICES.GATE_MANAGEMENT",
        //   icon: "db-icon-gate",
        //   routerLinkName: "/admin/gates",
        //   permissions: Permissions.Gates,
        // },
      ],
    },
  ];
}
