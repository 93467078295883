export enum Status {
  Active = 1,
  InActive,
  WaitingPayment,
  Paid,
  WaitingAdminApproval,
  Approved,
  Suspended,
  Rejected,
  PaymentDelayed,
  Free,
  WaitingCustomerEdits,
}
