@if (menuTemplateRef) {
  <div class="w-100" [matMenuTriggerFor]="menu">
    <ng-container [ngTemplateOutlet]="menuTemplateRef"></ng-container>
  </div>
} @else {
  <app-button
    [btnLabel]="btnLabel"
    [btnClass]="btnClass"
    [matMenuTriggerFor]="menu"
    [color]="color"
    iconPosition="left"
    (menuClosed)="onMenuClosed.emit()"
    (menuOpened)="onMenuOpened.emit()"
  >
    <ng-content></ng-content>
  </app-button>
}

<mat-menu class="{{ menuClass }}" [backdropClass]="backdropClass" #menu="matMenu">
  <div class="menu-item-hover">
    @if (menuItems.length) {
      @for (menuItem of menuItems; track $index) {
        <a
          class="h-40 overflow-visible {{ menuItem.menuItemClass }}"
          mat-menu-item
          [routerLink]="menuItem.menuItemRouterLink"
          [queryParams]="menuItem.menuItemQueryParams"
          [routerLinkActive]="menuItem.menuItemRouterLinkActive ?? 'text-primary'"
          (click)="menuItem.menuItemClick && menuItem.menuItemClick(menuItem)"
        >
          <span class="d-flex align-items-center font-12 menu-item-text text-capitalize w-100 h-100">
            <ng-container [ngTemplateOutlet]="menuItemTemplateRef" [ngTemplateOutletContext]="{ $implicit: menuItem }"></ng-container>
          </span>
        </a>
      }
    } @else {
      <span class="overflow-visible" mat-menu-item>
        <span class="d-flex align-items-center font-12 text-capitalize w-100 h-100">
          <ng-container [ngTemplateOutlet]="menuItemTemplateRef"></ng-container>
        </span>
      </span>
    }
  </div>
</mat-menu>
