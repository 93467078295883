<div
  class="mat-mdc-form-field-wrapper form-group d-flex flex-column m-0"
  [class.mb-3]="control.errors && control.touched"
  (isEnglish)="setCalendarLocale($event)"
>
  @if (isRangeDate) {
    <mat-form-field class="d-block mat-date-range" [appearance]="appearance" [color]="color">
      <mat-label class="text-middle">
        {{ label | translate }}
      </mat-label>

      <mat-date-range-input [rangePicker]="dateRangePicker">
        <input
          matStartDate
          [formControl]="startDateRange"
          (keydown)="preventDefault($event)"
          (dateChange)="setRangeDateValue($event, 'start')"
        />

        <input matEndDate [formControl]="endDateRange" (keydown)="preventDefault($event)" (dateChange)="setRangeDateValue($event, 'end')" />
      </mat-date-range-input>

      @if (startDateRange.value || endDateRange.value) {
        <mat-datepicker-toggle matIconSuffix (click)="resetDateRangeValue()">
          <em matDatepickerToggleIcon class="db-icon-plus remove font-14"></em>
        </mat-datepicker-toggle>
      }

      <mat-datepicker-toggle matIconSuffix [for]="dateRangePicker">
        <em matDatepickerToggleIcon class="db-icon-datepicker font-20"></em>
      </mat-datepicker-toggle>

      <mat-date-range-picker #dateRangePicker></mat-date-range-picker>

      @if (control.errors) {
        <mat-error [class]="matErrorClass">
          <span class="font-12">
            {{ control.errors | validationHandler }}
          </span>
        </mat-error>
      }
    </mat-form-field>
  } @else {
    <!-- single date -->
    <mat-form-field class="d-block mat-datepicker" [appearance]="appearance">
      <mat-label class="text-middle">
        {{ label | translate }}
      </mat-label>

      <input
        matInput
        [matDatepicker]="datepicker"
        [formControl]="control"
        [min]="minDate"
        [max]="maxDate"
        (keydown)="preventDefault($event)"
        (dateChange)="setDateValue($event)"
      />

      @if (control.value) {
        <mat-datepicker-toggle matIconSuffix (click)="control.reset()">
          <em matDatepickerToggleIcon class="db-icon-plus remove font-14"></em>
        </mat-datepicker-toggle>
      }

      <mat-datepicker-toggle matIconSuffix [for]="datepicker">
        <em matDatepickerToggleIcon class="db-icon-datepicker font-20"></em>
      </mat-datepicker-toggle>

      <mat-datepicker #datepicker></mat-datepicker>

      @if (control.errors) {
        <mat-error [class]="matErrorClass">
          <span class="font-12">
            {{ control.errors | validationHandler }}
          </span>
        </mat-error>
      }
    </mat-form-field>
  }
</div>

<!-- Usage
  <app-datepicker
    formControlName="xyz"
    [label]="'LABEL' | translate"
  >
  </app-datepicker>
-->
