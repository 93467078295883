import { Routes } from "@angular/router";

export const guidedToursRoutes: Routes = [
  {
    path: "",
    title: "SERVICES.GuidedTours",
    loadComponent: () =>
      import("./pages/guided-tours-programs-list/guided-tours-programs-list.component").then((m) => m.GuidedToursProgramsListComponent),
  },
];

export const guidedToursManagementRoutes: Routes = [
  {
    path: "add",
    title: "GUIDED_TOURS.ADD",
    loadComponent: () =>
      import("./pages/add-edit-guided-tour-program/add-edit-guided-tour-program.component").then(
        (m) => m.AddEditGuidedTourProgramComponent,
      ),
  },
  {
    path: "edit/:id",
    title: "GUIDED_TOURS.EDIT",
    loadComponent: () =>
      import("./pages/add-edit-guided-tour-program/add-edit-guided-tour-program.component").then(
        (m) => m.AddEditGuidedTourProgramComponent,
      ),
  },
];
