import { timer } from "rxjs";
import { TranslateModule } from "@ngx-translate/core";
import { Component, HostListener, inject } from "@angular/core";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";

import { AuthService } from "src/app/core";
import { ButtonComponent, InputTextComponent } from "src/app/shared";

@Component({
  selector: "app-verify",
  standalone: true,
  imports: [RouterLink, TranslateModule, ReactiveFormsModule, InputTextComponent, ButtonComponent],
  templateUrl: "./verify.component.html",
  styleUrl: "./verify.component.scss",
})
export class VerifyComponent {
  private fb = inject(FormBuilder);
  private router = inject(Router);
  private authService = inject(AuthService);
  private activatedRoute = inject(ActivatedRoute);

  otpForm!: FormGroup;
  allowResend = true;
  otpValue = "";
  counter = "";

  private resendTimes = 0;
  private resendMethods: Record<number, number[]> = {
    1: [1, 0],
    2: [3, 0],
    3: [5, 0],
  };

  get token() {
    return this.activatedRoute.snapshot.queryParamMap.get("token") || "";
  }

  get userEmail() {
    if (!this.token) return;

    const userToken = this.authService.convertTokenJWT(this.token);
    if (!userToken) return false;

    return userToken.Email;
  }

  get userPhoneNumber() {
    return localStorage.getItem("phone");
  }

  get otp() {
    return this.otpForm?.get("otp");
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

    if (this.token) {
      this.initVerifyEmailForm();
      this.verifyOtp();
    } else this.initVerifyOTPForm();

    this.countDown(0, 30);
  }

  initVerifyOTPForm() {
    this.otpForm = this.fb.group({
      otp: [null, Validators.required],
      phoneNumber: [localStorage.getItem("phone"), Validators.required],
    });
  }

  initVerifyEmailForm() {
    this.otpForm = this.fb.group({
      confirmationToken: [this.token],
    });
  }

  verifyOtp() {
    this.authService.confirmOtp(this.otpForm.value).subscribe({
      next: () => this.router.navigateByUrl("/auth/login"),
      error: () => this.resetOTPInputValue(),
    });
  }

  resendOTP() {
    const username = localStorage.getItem("username");

    let body: Record<string, unknown> = {};

    if (username?.includes("@")) {
      body = {
        email: username,
      };
    } else {
      if (!this.otpForm.get("phoneNumber")?.value) {
        this.otpForm.get("phoneNumber")?.markAsTouched();
        return;
      }

      body = {
        phone: this.userPhoneNumber || this.otpForm.get("phoneNumber")?.value,
      };
    }

    this.resendTimes += 1;
    this.countDown(this.resendMethods[this.resendTimes][0], this.resendMethods[this.resendTimes][1]);
    this.authService.resendMobileOtp(body).subscribe();
  }

  onKeyUpEvent(index: number, event: KeyboardEvent) {
    const eventCode = +event.code;

    if (this.getCodeBoxElement(index)["value"].length === 1) {
      if (index !== 4) {
        this.getCodeBoxElement(index + 1)?.focus();
      } else {
        this.getCodeBoxElement(index)?.blur();

        for (let item = 1; item <= index; item++) {
          const currentElement = this.getCodeBoxElement(item);

          if (currentElement && currentElement["value"]) {
            this.otpValue = this.otpValue + currentElement["value"];
          }
        }

        this.otpForm.get("otp")?.setValue(this.otpValue);
      }
    } else {
      this.otpValue = "";
      this.otpForm.get("otp")?.setValue(this.otpValue);
    }

    if (eventCode === 8 && index !== 1) {
      this.getCodeBoxElement(index - 1).focus();
    }
  }

  onKeyDownEvent(event: KeyboardEvent) {
    const eventCode = +event.code;

    if (eventCode !== 8) {
      if ((event.target as HTMLInputElement)?.value) {
        (event.target as HTMLInputElement).value = "";
      }
    }
  }

  onFocusEvent(index: number) {
    for (let item = 1; item < index; item++) {
      const currentElement = this.getCodeBoxElement(item);

      if (!currentElement["value"]) {
        currentElement.focus();
        break;
      }
    }
  }

  private getCodeBoxElement(index: number) {
    return (document.getElementById("codeBox" + index) as HTMLInputElement) || null;
  }

  private resetOTPInputValue() {
    this.otpForm.get("otp")?.reset();

    for (let i = 0; i < 4; i++) {
      const currentElement = this.getCodeBoxElement(i + 1);
      currentElement["value"] = "";
    }
  }

  private countDown(minutes: number, seconds: number): void {
    this.allowResend = false;

    const targetDate = new Date();
    targetDate.setMinutes(targetDate.getMinutes() + minutes);
    targetDate.setSeconds(targetDate.getSeconds() + seconds + 1);

    const subscription = timer(0, 1000).subscribe(() => {
      const currentDate = new Date(),
        remainingTime = targetDate.getTime() - currentDate.getTime();

      if (remainingTime <= 0) {
        subscription.unsubscribe(); // Stop the countdown when it reaches zero
        this.allowResend = true;
      } else {
        const minutes = Math.floor(remainingTime / (1000 * 60)),
          seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

        this.counter = `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
      }
    });
  }

  @HostListener("paste", ["$event"])
  pastValue(event: ClipboardEvent) {
    this.otpValue = event.clipboardData?.getData("Text") || "";

    setTimeout(() => {
      this.otpValue.split("").map((number, index) => {
        const currentElement = this.getCodeBoxElement(index + 1);
        if (currentElement) currentElement["value"] = number;
      });

      if (this.otpForm.get("otp")) this.otpForm.get("otp")?.setValue(this.otpValue);
    });
  }
}
