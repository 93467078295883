import { BreakpointObserver } from "@angular/cdk/layout";
import { effect, inject, Injectable } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { BehaviorSubject, map } from "rxjs";
import { environment } from "src/environments/environment";

type Theme = "dark" | "light";

@Injectable({
  providedIn: "root",
})
export class ThemeService {
  private breakpointObserver = inject(BreakpointObserver);
  private layoutChanges = toSignal(
    this.breakpointObserver.observe(["(prefers-color-scheme: dark)"]).pipe(map((breakpointsStates) => breakpointsStates.breakpoints)),
  );

  private theme: Theme = (localStorage.getItem("projectTheme") as Theme) || environment.mainTheme;
  #currentTheme = new BehaviorSubject<Theme>(this.theme);

  currentTheme$ = this.#currentTheme.asObservable();

  get isDark() {
    return this.theme === "dark";
  }

  get currentTheme() {
    return this.theme;
  }

  constructor() {
    this.initializeTheme();
  }

  toggleTheme() {
    this.setTheme(this.theme === "dark" ? "light" : "dark");
  }

  private initializeTheme() {
    effect(() => {
      const isDark = this.layoutChanges()!["(prefers-color-scheme: dark)"];

      this.theme = isDark ? "dark" : "light";
      this.setTheme(this.theme);
    });
  }

  private setTheme(theme: Theme) {
    localStorage.setItem("projectTheme", theme);

    this.#currentTheme.next(this.theme);
    document.querySelector("html")?.setAttribute("data-bs-theme", theme);
  }
}
