<div class="bg-primary h-100" [breakpoints]="breakpoints" (breakpointsStates)="getBreakpointsStates($event)">
  <div class="page-wrapper d-flex flex-column h-100">
    <div class="d-flex align-items-center justify-content-center">
      <h1 class="m-0 fw-bold text-white font-30">{{ "SERVICES.SECTION" | translate }}</h1>
    </div>

    <div class="mt-4 h-100">
      <div class="w-100">
        @if (isNormalScreen) {
          @for (eachSortedServices of sortedServices; track $index) {
            <div class="row justify-content-center" [class.my-4]="$index === 1">
              @for (eachSortedService of eachSortedServices; track $index) {
                <div class="col-3">
                  <app-service-card [service]="eachSortedService" titleClass="fw-bold" />
                </div>
              }
            </div>
          }
        } @else {
          <div class="services d-grid">
            @for (service of services; track $index) {
              <app-service-card [service]="service" titleClass="fw-bold" />
            }
          </div>
        }
      </div>
    </div>
  </div>
</div>
