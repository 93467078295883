import { Location } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { Component, ViewEncapsulation, inject } from "@angular/core";
import { ActivatedRoute, Router, RouterOutlet } from "@angular/router";

import { ButtonComponent } from "src/app/shared";
import { ReservationService, ParentServiceCode, UserRole, AuthService } from "src/app/core";

@Component({
  selector: "app-book",
  standalone: true,
  imports: [RouterOutlet, TranslateModule, ButtonComponent],
  templateUrl: "./book.component.html",
  styleUrl: "./book.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class BookComponent {
  private router = inject(Router);
  private location = inject(Location);
  private authService = inject(AuthService);
  private activatedRoute = inject(ActivatedRoute);
  private reservationService = inject(ReservationService);

  kioskMode = false;

  parentServiceCode!: ParentServiceCode;
  serviceCode!: ParentServiceCode;

  get isEventTicket() {
    return [ParentServiceCode.EVENT].includes(this.parentServiceCode);
  }

  get isGuest() {
    return this.reservationService.convertTokenJWT()?.RoleCode === UserRole.GUEST;
  }

  get isAdmin() {
    return this.reservationService.convertTokenJWT()?.RoleCode === UserRole.ADMIN;
  }

  get isCounter() {
    return this.reservationService.convertTokenJWT()?.RoleCode === UserRole.COUNTER;
  }

  get isCompany() {
    return this.authService.isCompanyUser;
  }

  get discountValue() {
    return this.authService.discountValue;
  }

  get isPercentageDiscount() {
    return this.authService.isPercentageDiscount;
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

    this.parentServiceCode = this.activatedRoute.snapshot.routeConfig?.path as ParentServiceCode;
    this.serviceCode = this.activatedRoute.snapshot.firstChild?.routeConfig?.path as ParentServiceCode;

    this.kioskMode = this.activatedRoute.snapshot.data["kioskMode"];
  }

  back() {
    this.location.back();
  }

  logOut() {
    localStorage.clear();
    // this.authService.userPermissions.next(null);
    this.router.navigate(["/"]);
  }
}
