import { Injectable } from "@angular/core";
import { HttpService, Product } from "src/app/core";

@Injectable({
  providedIn: "root",
})
export class ProductsService extends HttpService {
  protected override get baseUrl(): string {
    return "products/";
  }

  getAll() {
    return this.get<Product[]>({ APIName: "getall" });
  }

  addProduct(product: Product) {
    return this.post<Product>({ APIName: "add", body: product });
  }

  updateProduct(product: Product) {
    return this.put<Product>({ APIName: "update", body: product });
  }

  getProductById(id: string) {
    return this.get<Product>({ APIName: `get-id/${id}` });
  }

  removeProduct(id: string) {
    return this.get<Product>({ APIName: `delete/${id}` });
  }

  getProductsByTypeId(id: number) {
    return this.get<Product[]>({ APIName: `get-product-type/${id}` });
  }
}
